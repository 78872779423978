import React, { Component } from "react";
import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import MenuApi from "../../Services/MenuApi";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../../Redux/StoreLoginRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Api from "../../Services/Api";
import StoreLoginApi from "../../Services/StoreLoginApi";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import {
  Grid,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import styles from "./style.module.scss";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class PhoneLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantClosed: false,
      username: "",
      password: "",
      showPasswordLogin: false,
      restaurantErrorMessage: "",
    };
  }

  componentDidMount() {
    this.getRestaurantInfo();
  }

  getRestaurantInfo = () => {
    const apiService = Api.create();
    apiService
      .getRestaurantInfo(
        this.props.match?.params?.restaurant,
        this.props.match?.params?.location == "main"
          ? null
          : this.props.match?.params?.location
      )
      .then((response) => {
        if (response.status == 200 && response.data?.business_id) {
          const responseInfo = response.data;
          this.props.dispatch(
            OrderActions.setNewRestaurant(
              responseInfo.business_id,
              responseInfo.location_id,
              responseInfo.intersection_plaza,
              false,
              responseInfo
            )
          );
          if (!responseInfo?.is_pick_up_available) {
            this.props.dispatch(LoginActions.setOrderType(1));
          }
          if (!this.isAsapAvailable()) {
            this.props.dispatch(LoginActions.setIsScheduled(true));
            this.setState({ isScheduled: true });
          }
        } else {
          // TODO: Failure to get restaurant info
          this.setState({
            restaurantClosed: true,
            restaurantErrorMessage: response?.data?.status_message,
          });
        }
      });
  };

  isContinueDisabled = () => {
    if (
      this.state.isScheduled &&
      this.state.datePicked == "" &&
      this.state.timePicked == ""
    ) {
      return true;
    }
    // Delivery
    if (this.props.orderType == 1 && this.state.latitude == "") {
      return true;
    }
  };

  isAsapAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_asap_delivery;
    else return this.props.restaurantInfo?.info?.is_asap_pickup;
  };

  isScheduleAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_scheduled_delivery;
    else return this.props.restaurantInfo?.info?.is_scheduled_pickup;
  };

  getMenu = () => {
    if (!this.isContinueDisabled()) {
      if (
        this.props.restaurantInfo?.info?.business_id &&
        this.props.restaurantInfo?.info?.location_id
      ) {
        const requestObj = {
          business_id: this.props.restaurantInfo?.info?.business_id,
          location_id: this.props.restaurantInfo?.info?.location_id,
          order_type: "2",
        };
        const apiService = MenuApi.create();
        let moreRestaurantDetails = {};
        apiService
          .getRestaurantDetailsPhoneOrder(requestObj)
          .then((response) => {
            if (!response.data.available) {
              this.setState({ deliveryError: response.data?.reason });
            } else {
              if (!response.data?.status) {
                moreRestaurantDetails = response.data;
              }
              const restaurantInfo = {
                ...this.props.restaurantInfo?.info,
                ...moreRestaurantDetails,
              };
              this.props.dispatch(
                OrderActions.setNewRestaurant(
                  restaurantInfo.business_id,
                  restaurantInfo.location_id,
                  restaurantInfo.intersection_plaza,
                  false,
                  restaurantInfo
                )
              );

              this.goToMenuScreen(
                this.props.restaurantInfo?.info?.business_id,
                this.props.restaurantInfo?.info?.location_id
              );
            }
          });
      }
    }
  };

  goToMenuScreen = (business_id, location_id) => {
    this.props.dispatch(
      MenuActions.menuRequest(business_id, location_id, "2", null)
    );
    this.props.history.push(`${this.props.match.url}/order`);
  };

  login = (event) => {
    event.preventDefault();
    if (this.state.password.length < 6) {
    } else if (this.state.password != "" && this.state.username != "") {
      const apiService = StoreLoginApi.create();
      apiService
        .storeLoginRequest(
          this.state.username,
          this.state.password,
          this.props.restaurantInfo?.info?.business_id,
          this.props.restaurantInfo?.info?.location_id
        )
        .then((response) => {
          if (response.ok && response.data.status !== "Failure") {
            const userInfo = response.data;
            this.props.dispatch(
              StoreLoginActions.storeLoginRequestSuccess(userInfo)
            );
            this.getMenu();
            toast.success("Welcome " + response.data.name);
          } else {
            toast.error("Login Failed");
          }
        });
    }
  };

  checkLogin = () => {
    if (this.props.isUserLoggedIn) {
      this.getMenu();
    }
  };

  render() {
    this.checkLogin();
    return (
      <>
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={styles.backgroudImage}
            style={{
              minHeight: "100vh",
              backgroundImage:
                "url(" +
                this.props.restaurantInfo?.info?.background_image +
                ")",
            }}
          >
            {this.state.restaurantClosed && (
              <Grid container justify="center" className={styles.mainSection}>
                <Grid
                  container
                  style={{ minHeight: "30vh" }}
                  justify="center"
                  alignContent="center"
                >
                  <span style={{ fontStyle: "italic" }}>
                    {this.state.restaurantErrorMessage}
                  </span>
                </Grid>
              </Grid>
            )}
            {!this.state.restaurantClosed && (
              <Grid container justify="center" className={styles.mainSection}>
                <Grid item xs={12} className={styles.topSection}>
                  <Grid container justify="center" alignItems="center">
                    {this.props.restaurantInfo?.info?.logo && (
                      <Grid item xs={12}>
                        <div style={{ textAlign: "center" }}>
                          <img
                            className={styles.logo}
                            src={this.props.restaurantInfo?.info?.logo}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {this.props.restaurantInfo?.info
                        ?.logo_has_business_name !== "1" && (
                        <h1 style={{ textAlign: "center" }}>
                          {this.props.restaurantInfo?.info?.business_name}
                        </h1>
                      )}
                      <span style={{ textAlign: "center" }}>
                        {this.props.restaurantInfo?.info?.business_location}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.topSection}>
                  <form className={styles.tabpanel} onSubmit={this.login}>
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-email">Email</InputLabel>
                        <Input
                          fullWidth={true}
                          id="signup-email"
                          aria-describedby="signup-email-text"
                          onChange={(username) =>
                            this.setState({ username: username.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-phone">Password</InputLabel>
                        <Input
                          type="password"
                          id="signup-password"
                          aria-describedby="signup-password-text"
                          type={
                            this.state.showPasswordLogin ? "text" : "password"
                          }
                          onChange={(value) =>
                            this.setState({ password: value.target.value })
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.setState({
                                    showPasswordLogin: !this.state
                                      .showPasswordLogin,
                                  })
                                }
                                onMouseDown={(event) => event.preventDefault()}
                              >
                                {this.state.showPasswordLogin ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                    <br />

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Button
                          color="secondary"
                          variant="contained"
                          type="submit"
                        >
                          {this.state.forgotPassword ? "Submit" : "Login"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        {/* <Switch>
          <Route exact path={`${this.props.match.path}/order`} component={OrderScreen} />
          <Route exact path={`${this.props.match.path}/checkout`} component={Checkout} />
          <Route exact path={`${this.props.match.path}/orderHistory`} component={OrderHistory} />
        </Switch> */}
      </>

      // <Button variant="contained" onClick={() => this.goToMenuScreen()}>Get Menu</Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    isUserLoggedIn: StoreLoginSelectors.isUserloggedIn(state),
    orderType: StoreLoginSelectors.getOrderTypeId(state),
    isScheduled: StoreLoginSelectors.getIsScheduled(state),
    selectedAddress: StoreLoginSelectors.getSelectedAddress(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
  };
};

export default withRouter(connect(mapStateToProps)(PhoneLogin));
