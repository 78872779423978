import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import {
  Grid,
  Card,
  Modal,
  Box,
  Paper,
  Button,
  Hidden,
  withTheme,
  withStyles,
} from "@material-ui/core";
import { LoginSelectors } from "../../Redux/LoginRedux";
import LoginActions from "../../Redux/LoginRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import CustomerActions, { CustomerSelectors } from "../../Redux/CustomerRedux";
import CardContent from "@material-ui/core/CardContent";
import CustomizeItem from "../../components/CustomizeItem/CustomizeItem";
import RestaurantInfo from "../../components/RestaurantInfo/RestaurantInfo";
import styles from "./style.module.scss";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import StickyBox from "react-sticky-box";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import OrderStatusBlock from "../../components/OrderStatusBlock/OrderStatusBlock";
import { Link, Element } from "react-scroll";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../../Redux/StoreLoginRedux";
import NavBar from "../../components/Navbar/Navbar";
import createUrlToScreen from "../../Services/Utils";
import Loading from "../../components/Loading/Loading";
import Api from "../../Services/Api";
import { toast } from "react-toastify";
import InfoIcon from "@material-ui/icons/Info";
import PhoneOrderSidebar from "../../components/PhoneOrderSidebar/PhoneOrderSidebar";
import background_1 from "../../assets/images/backimage_1.jpeg";
import { Helmet } from "react-helmet";
import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import MenuApi from "../../Services/MenuApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const classes = (theme) => {
  return {
    activeCategoryLink: {
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
    listItem: {
      paddingTop: 5,
      paddingBottom: 10,
      fontSize: 16,
      "&:hover": {
        color: theme.palette.secondary.main,
        cursor: "pointer",
      },
      [theme.breakpoints.down(960)]: {
        display: "inline",
        marginRight: 20,
      },
    },
  };
};

class OrderScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEditItemId: "",
      showloginModal: false,
      editMode: false,
      editIndex: -1,
      checkoutModal: false,
      showLoadingAnimation: true,
      showLoadingAnimationText: "Loading Menu",
      phoneOrder: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.phoneOrderSidebarRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  shouldComponentUpdate() {
    this.props.categories.forEach(
      (category) => (this[category.category_id] = React.createRef())
    );
    if (this.state.phoneOrder && !this.props.isStoreUserloggedIn) {
      this.props.history.push(createUrlToScreen("", this.props.match.url));
    }
    return true;
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setPhoneOrder();
    if (
      this.props.loadingState.loading != false &&
      this.props.loadingState.loading != true &&
      this.props.menu_items?.length == 0
    ) {
      this.props.history.push(createUrlToScreen("", this.props.match.url));
    }
    this.setLoadingAnimationTimeout();
  };

  setLoadingAnimationTimeout = () => {
    setTimeout(() => this.setState({ showLoadingAnimation: false }), 500);
  };

  getMenuItemsByCategory = (category_id) => {
    return this.props.menu_items.filter(
      (item) => item.category_id == category_id
    );
  };

  openCheckoutModal = () => {
    this.setState({
      checkoutModal: true,
    });
  };

  getProductName = (id) => {
    let menuItem = "";
    if (this.props.orderHistoryFlag) {
      menuItem = this.props.menuForHistoryOrder.menu_item_list.filter(
        (item) => item.item_id == id
      );
    } else {
      menuItem = this.props.menu_items.filter((item) => item.item_id == id);
    }

    return menuItem[0].item_name;
  };

  goToCustomizeItemScreen = (item) => {
    // this.props.navigation.navigate('CustomizeItem', { item });

    if (this.state.phoneOrder) {
      if (this.props.activeCustomer) {
        this.setState({ currentEditItemId: item.item_id });
      } else {
        toast.error("Please Add Customer to Order");
      }
    } else {
      this.setState({ currentEditItemId: item.item_id });
    }
  };

  editProduct = (id, index) => {
    this.setState({ currentEditItemId: id, editMode: true, editIndex: index });
  };

  scrollToCategory = (id) => {
    const left = this[id]?.current?.getBoundingClientRect().left;
    if (left && (left < 0 || left >= window.innerWidth)) {
      this[id].current.scrollIntoView({ inline: "center" });
    }
  };

  itemAddedToOrder = () => {
    if (this.state.phoneOrder) {
      this.phoneOrderSidebarRef.removeCoupon();
    }
  };

  findLowestItemCost = (item_variation_list) => {
    var lowestCost = item_variation_list[0].variation_cost;
    item_variation_list.map((item) => {
      if (item.variation_cost < lowestCost) {
        lowestCost = item.variation_cost;
      }
    });
    return lowestCost;
  };

  menuItems = (category_id) => {
    return this.getMenuItemsByCategory(category_id).map((item) => (
      // <ListItem
      //     key={item.item_id}
      //     title={item.item_name}
      //     titleStyle={styles.menuTitle}
      //     bottomDivider
      //     rightTitle={'$' + item.item_cost.toFixed(2)}
      //     rightTitleStyle={styles.rightTitleStyle}
      //     onPress={() => this.goToCustomizeItemScreen(item)}
      //     subtitle={
      //         item.description && item.description.length > 53
      //             ? item.description.substring(0, 53) + '...'
      //             : item.description
      //     }
      //     subtitleStyle={styles.menuSubtitle}
      //     containerStyle={{ paddingLeft: 20, paddingRight: 20 }}
      // />
      <>
        <Card
          variant="outlined"
          key={item.item_id}
          onClick={() => this.goToCustomizeItemScreen(item)}
          className={styles.menuCard}
        >
          <CardContent>
            <Grid container>
              <Hidden only="xs">
                <Grid item xs={item.item_image !== "" ? 8 : 12}>
                  <div className="font16Bold">{item.item_name}</div>
                  <div className={`subhead ${styles.menuSubHead}`}>
                    {item.description && item.description.length > 80
                      ? item.description.substring(0, 80) + "..."
                      : item.description}
                  </div>
                  {item.item_variation_list.length > 0 && (
                    <>
                      <div className="subhead">Starting From</div>
                      <div className="font16Bold">
                        {"$" +
                          this.findLowestItemCost(
                            item.item_variation_list
                          ).toFixed(2)}
                      </div>
                    </>
                  )}
                  {item.item_variation_list.length == 0 && (
                    <>
                      <div className="font16Bold">
                        {"$" + item.item_cost.toFixed(2)}
                      </div>
                    </>
                  )}
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item xs={item.item_image !== "" ? 8 : 12}>
                  <div className="font16Bold">{item.item_name}</div>
                  <div className={`subhead ${styles.menuSubHead}`}>
                    {item.description && item.description.length > 25
                      ? item.item_image
                        ? item.description.substring(0, 25) + "..."
                        : item.description.substring(0, 80) + "..."
                      : item.description}
                  </div>
                  {item.item_variation_list.length > 0 && (
                    <>
                      <div className="subhead">Starting From</div>
                      <div className="font16Bold">
                        {"$" +
                          this.findLowestItemCost(
                            item.item_variation_list
                          ).toFixed(2)}
                      </div>
                    </>
                  )}
                  {item.item_variation_list.length == 0 && (
                    <>
                      <div className="font16Bold">
                        {"$" + item.item_cost.toFixed(2)}
                      </div>
                    </>
                  )}
                </Grid>
              </Hidden>
              {item.item_image !== "" && !this.state.phoneOrder && (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  alignItems="flex-end"
                  className={styles.itemImageContainer}
                >
                  <img
                    src={item.item_image}
                    alt="Item"
                    className={styles.itemImage}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        <Modal
          key={"m" + item.item_id}
          open={this.state.currentEditItemId == item.item_id}
          onClose={() => this.setState({ currentEditItemId: "" })}
          disableBackdropClick={this.state.editMode}
        >
          <div className={styles.modalStyle} id="modifiers">
            <CustomizeItem
              item={item}
              closeItemSelection={() =>
                this.setState({ currentEditItemId: "" })
              }
              closeItemEdit={() =>
                this.setState({ currentEditItemId: "", editMode: false })
              }
              edit={this.state.editMode}
              editIndex={this.state.editIndex}
              productAddedNotifier={this.itemAddedToOrder}
            />
          </div>
        </Modal>
      </>
    ));
  };

  isRestaurantAvailable = async () => {
    if (this.props.orderType == 2 || !this.props.isScheduled) {
      const apiServiceRestaurant = Api.create();
      return await apiServiceRestaurant
        .getRestaurantInfo(
          this.props.match?.params?.restaurant,
          this.props.match?.params?.location == "main"
            ? null
            : this.props.match?.params?.location
        )
        .then((response) => {
          if (response.status == 200 && response.data?.business_id) {
            if (this.props.orderType == 2) {
              if (!response.data?.is_pick_up_available) {
                return false;
              }
            }
            if (!this.props.isScheduled) {
              if (!this.isAsapAvailable(response.data)) {
                return false;
              }
            } else {
              if (!this.isScheduleAvailable(response.data)) {
                return false;
              }
            }
            return true;
          } else {
            return false;
          }
        });
    } else {
      return true;
    }
  };

  checkout = () => {
    this.isRestaurantAvailable().then((result) => {
      if (result) {
        if (this.props.isUserLoggedIn) {
          this.props.history.push(
            createUrlToScreen("checkout", this.props.match.url)
          );
        } else {
          this.setState({ showloginModal: true, showLoadingAnimation: false });
        }
      } else {
        toast.error(
          "Restaurant is not available at the moment, change order settings"
        );
      }
    });
  };

  orderSentLoading = (loading) => {
    this.setState({
      showLoadingAnimation: loading,
      showLoadingAnimationText: "Placing Order",
    });
  };

  isAsapAvailable = (restaurantInfo) => {
    if (this.props.orderType == 1) return restaurantInfo.is_asap_delivery;
    else return restaurantInfo.is_asap_pickup;
  };

  isScheduleAvailable = (restaurantInfo) => {
    if (this.props.orderType == 1) return restaurantInfo.is_scheduled_delivery;
    else return restaurantInfo.is_scheduled_pickup;
  };

  signOut = () => {
    this.props.dispatch(LoginActions.logOut());
  };

  logIn = () => {
    this.setState({ showloginModal: true });
  };

  setPhoneOrder = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const location = this.props.location.pathname.split("/");
    if (location[1] == "web" && this.state.phoneOrder) {
      this.setState({ phoneOrder: false });
      this.setThemeColor();
    } else if (location[1] == "phone-order" && !this.state.phoneOrder) {
      this.setState({ phoneOrder: true });
      this.getMenu();
      this.props.dispatch(CustomerActions.clearCustomers());
      this.props.dispatch(StoreLoginActions.clearUserData());
      this.props.dispatch(OrderActions.phoneOrderSuccessful());
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    } else {
      this.setState({ phoneOrder: false });
      this.setThemeColor();
    }
  };

  getMenu = () => {
    if (this.props.isStoreUserloggedIn) {
      if (
        this.props.restaurantInfo?.info?.business_id &&
        this.props.restaurantInfo?.info?.location_id
      ) {
        const requestObj = {
          business_id: this.props.restaurantInfo?.info?.business_id,
          location_id: this.props.restaurantInfo?.info?.location_id,
          order_type: "2",
        };
        const apiService = MenuApi.create();
        let moreRestaurantDetails = {};
        apiService
          .getRestaurantDetailsPhoneOrder(requestObj)
          .then((response) => {
            if (!response.data.available) {
              this.setState({ deliveryError: response.data?.reason });
            } else {
              if (!response.data?.status) {
                moreRestaurantDetails = response.data;
              }
              const restaurantInfo = {
                ...this.props.restaurantInfo?.info,
                ...moreRestaurantDetails,
              };
              this.props.dispatch(
                OrderActions.setNewRestaurant(
                  restaurantInfo.business_id,
                  restaurantInfo.location_id,
                  restaurantInfo.intersection_plaza,
                  false,
                  restaurantInfo
                )
              );

              this.goToMenuScreen(
                this.props.restaurantInfo?.info?.business_id,
                this.props.restaurantInfo?.info?.location_id
              );
            }
          });
      }
    }
  };

  goToMenuScreen = (business_id, location_id) => {
    this.props.dispatch(
      MenuActions.menuRequest(business_id, location_id, "2", null)
    );
  };

  setThemeColor = () => {
    if (
      this.props.restaurantInfo?.info?.theme_color &&
      this.props.restaurantInfo?.info?.theme_color !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(
        this.props.restaurantInfo?.info?.theme_color
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              {this.props.restaurantInfo?.info?.business_name} | Order On The Go
            </title>
          </Helmet>
        )}
        {!this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              Order On The Go | Takeout and Delivery from your favorite
              restaurants
            </title>
          </Helmet>
        )}
        {this.state.phoneOrder && this.state.width < 962 && (
          <Box
            className={styles.backgroudImage}
            style={{
              minHeight: "100vh",
              backgroundImage: "url(" + background_1 + ")",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              width: "100%",
              overflow: "hidden",
              height: "100vh",
            }}
          >
            <NavBar ootg={true} />
            <Container
              className={styles.mainBox}
              fluid
              maxWidth={false}
              disableGutters={true}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  justify="center"
                  alignItems="center"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#fff",
                    padding: 20,
                    margin: 50,
                  }}
                >
                  <span>
                    This page does not work for your screensize. If you are
                    using ipad then turn into landscape mode to work with this
                    page.
                  </span>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
        {!(this.state.phoneOrder && this.state.width < 962) && (
          <>
            {(this.state.loadingState?.loading ||
              this.state.showLoadingAnimation) && (
              <Box style={{ minHeight: "100vh" }}>
                <Grid
                  container
                  justify="space-around"
                  style={{ height: "100vh" }}
                  alignItems="center"
                  alignContent="center"
                >
                  <Loading title={this.state.showLoadingAnimationText} />
                </Grid>
              </Box>
            )}
            {!this.state.loadingState?.loading &&
              !this.state.showLoadingAnimation && (
                <Box className={styles.mainBox}>
                  <NavBar />
                  {!this.state.phoneOrder && (
                    <Container fluid maxWidth={false} disableGutters={true}>
                      {/* {this.props.coverImage && ( */}
                      <div
                        className={styles.backgroundImage}
                        style={{
                          backgroundImage: `url(${this.props.coverImage})`,
                        }}
                      >
                        {/* <Paper> */}
                        {/* <Container fluid maxWidth="lg"> */}
                        {/* </Container> */}
                        {/* </Paper> */}
                        <div className={styles.backgroundImageLayer}>
                          <RestaurantInfo />
                        </div>
                      </div>
                      {/* )} */}
                    </Container>
                  )}
                  <Container fixed className={styles.menuContainer}>
                    <Grid
                      container
                      spacing={2}
                      className={styles.stickyContainer}
                    >
                      {this.props.businessInfo.promotions && this.props.businessInfo.promotions?.length !== 0 && (
                        <Hidden mdUp>
                          <Grid item xs={12} style={{ marginBottom: 15 }}>
                            <Carousel
                              showArrows={false}
                              showThumbs={false}
                              infiniteLoop={true}
                              autoPlay={true}
                              swipeable={true}
                              dynamicHeight={false}
                              showStatus={false}
                            >
                              {this.props.businessInfo.promotions.map(
                                (promotion) => (
                                  <div>
                                    <img src={promotion.promotion_image} />
                                  </div>
                                )
                              )}
                            </Carousel>
                          </Grid>
                        </Hidden>
                      )}
                      <StickyBox
                        className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-3"
                        offsetTop={this.state.width < 962 ? 48 : 80}
                      >
                        <Grid className={styles.stickyDiv}>
                          <Box className={styles.categoryBox}>
                            <ul className={styles.ulItem}>
                              {this.props.categories.map((category) => (
                                <li
                                  className={classes.listItem}
                                  ref={this[category.category_id]}
                                >
                                  <Link
                                    activeClass={classes.activeCategoryLink}
                                    className={category.category_id}
                                    to={category.category_id}
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={this.state.width < 962 ? -155 : -90}
                                    onSetActive={() =>
                                      this.scrollToCategory(
                                        category.category_id
                                      )
                                    }
                                  >
                                    {category.category_name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                            {/* <Button onClick={() => this.props.isUserLoggedIn ? this.signOut() : this.setState({ showloginModal: true })}>Login</Button>
                    <Button onClick={() => this.props.history.push(createUrlToScreen('orderHistory', this.props.match.url))}>Order History</Button> */}
                          </Box>
                        </Grid>
                      </StickyBox>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={!this.state.phoneOrder ? 5 : 5}
                      >
                        {this.props.businessInfo.promotions && this.props.businessInfo.promotions.length !== 0 && (
                          <Hidden smDown>
                            <Grid item xs={12} style={{ marginBottom: 15 }}>
                              <Carousel
                                showArrows={false}
                                showThumbs={false}
                                infiniteLoop={true}
                                autoPlay={true}
                                swipeable={true}
                                dynamicHeight={false}
                                showStatus={false}
                              >
                                {this.props.businessInfo.promotions.map(
                                  (promotion) => (
                                    <div>
                                      <img src={promotion.promotion_image} />
                                    </div>
                                  )
                                )}
                              </Carousel>
                            </Grid>
                          </Hidden>
                        )}
                        {this.props.businessInfo.age_varification && (
                          <Grid item xs={12}>
                            <Card
                              classes={{ root: styles.allergyWarningStyle }}
                              elevation={0}
                            >
                              <CardContent>
                                <Grid
                                  container
                                  justify="center"
                                  alignItems="top"
                                >
                                  <Grid item xs={1}>
                                    <span
                                      style={{
                                        verticalAlign: "top",
                                        paddingRight: 10,
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faIdCard}
                                        style={{ fontSize: 18 }}
                                      />
                                    </span>
                                  </Grid>
                                  <Grid item xs={11}>
                                    {this.props.businessInfo.age_varification}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                        {this.props.businessInfo.allergy_warning && (
                          <Grid item xs={12}>
                            <Card
                              classes={{ root: styles.allergyWarningStyle }}
                              elevation={0}
                            >
                              <CardContent>
                                <Grid
                                  container
                                  justify="center"
                                  alignItems="top"
                                >
                                  <Grid item xs={1}>
                                    <span
                                      style={{
                                        verticalAlign: "top",
                                        paddingRight: 10,
                                      }}
                                    >
                                      <InfoIcon />
                                    </span>
                                  </Grid>
                                  <Grid item xs={11}>
                                    {this.props.businessInfo.allergy_warning}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {this.props.categories.map((category) => (
                            <Element
                              name={category.category_id}
                              className={category.category_id}
                              key={category.category_id + "display"}
                            >
                              {category.category_image &&
                                !this.state.phoneOrder && (
                                  <Box
                                    className={styles.categoryBackground}
                                    style={{
                                      backgroundImage:
                                        "url(" + category.category_image + ")",
                                    }}
                                  ></Box>
                                )}
                              <div className={styles.categoryTitleBox}>
                                <div
                                  className={`font20Bold ${styles.categoryTitle}`}
                                >
                                  {category.category_name}
                                </div>
                                <div
                                  className={`subhead ${styles.categoryDescription}`}
                                >
                                  {category.category_description}
                                </div>
                                <div>
                                  {this.menuItems(category.category_id)}
                                </div>
                              </div>
                            </Element>
                          ))}
                        </Grid>
                      </Grid>
                      <StickyBox
                        className={`MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12  MuiGrid-grid-sm-12 ${
                          !this.state.phoneOrder
                            ? "MuiGrid-grid-md-4"
                            : "MuiGrid-grid-md-4"
                        } ${styles.checkoutContainer}`}
                        offsetTop={this.state.width < 962 ? 48 : 80}
                      >
                        <Box>
                          {!this.state.phoneOrder && (
                            <OrderStatusBlock
                              editProduct={(id, index) =>
                                this.editProduct(id, index)
                              }
                              checkout={() => this.checkout()}
                            />
                          )}
                          {this.state.phoneOrder &&
                            this.props.isStoreUserloggedIn && (
                              <PhoneOrderSidebar
                                editProduct={(id, index) =>
                                  this.editProduct(id, index)
                                }
                                checkout={() => this.checkout()}
                                onRef={(ref) =>
                                  (this.phoneOrderSidebarRef = ref)
                                }
                                orderSentLoading={(loading) =>
                                  this.orderSentLoading(loading)
                                }
                              />
                            )}
                        </Box>
                      </StickyBox>
                      {this.props.selectedProducts.length > 0 &&
                        !this.state.checkoutModal && (
                          <StickyBox bottom={true} style={{ width: "100%" }}>
                            <Button
                              style={{ width: "100%" }}
                              variant="contained"
                              className={styles.floatingButton}
                              color="secondary"
                              onClick={() => this.openCheckoutModal()}
                            >
                              <Grid container>
                                <Grid item xs={6}>
                                  {this.props.selectedProducts.length} Items: $
                                  {this.props.subTotal}
                                </Grid>
                                <Grid item xs={6}>
                                  View Order
                                </Grid>
                              </Grid>
                            </Button>
                          </StickyBox>
                        )}
                    </Grid>
                  </Container>
                </Box>
              )}
            <Modal
              open={this.state.checkoutModal}
              onBackdropClick={() => this.setState({ checkoutModal: false })}
            >
              <div className={styles.loginModalStyle}>
                <OrderStatusBlock
                  editProduct={(id, index) => this.editProduct(id, index)}
                  checkout={() => this.checkout()}
                />
              </div>
            </Modal>
            <Modal open={this.state.showloginModal} disableBackdropClick>
              <div className={styles.loginModalStyle}>
                <LoginSignup
                  nextScreen="checkout"
                  onClose={() => this.setState({ showloginModal: false })}
                />
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: OrderSelectors.getProducts(state),
    subTotal: OrderSelectors.getSubTotal(state),
    categories: MenuSelectors.getCategories(state)
      ? MenuSelectors.getCategories(state)
      : [],
    menu_items: MenuSelectors.getMenuItems(state)
      ? MenuSelectors.getMenuItems(state)
      : [],
    loadingState: MenuSelectors.getMenuLoadingState(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
    coverImage: MenuSelectors.getCoverImage(state),
    intersectionPlaza: OrderSelectors.getIntersectionPlaza(state),
    selectedItems: OrderSelectors.getProducts(state),
    favourite: OrderSelectors.getFavourite(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    userInfo: LoginSelectors.getUserInfo(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    isScheduled: LoginSelectors.getIsScheduled(state),
    orderType: LoginSelectors.getOrderTypeId(state),
    isStoreUserloggedIn: StoreLoginSelectors.isUserloggedIn(state),
    activeCustomer: CustomerSelectors.getActiveCustomer(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withStyles(classes)(OrderScreen)))
);
