import { put, select, call } from "redux-saga/effects";
import OrderActions, { OrderSelectors } from "../Redux/OrderRedux";
// import { api_key } from "../Services/apiKey";
// import { MenuSelectors } from "../Redux/MenuRedux";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
// import createUrlToScreen from "../Services/Utils";

export function* sendOrderToBackend(api, action) {
  // let { currentUrl } = action;
  let finalOrderJson = yield select(OrderSelectors.getFinalOrderJson);
  let restaurantInfo = yield select(OrderSelectors.getRestaurantInfo);
  if (finalOrderJson.order_type === 3) {
    finalOrderJson.room_name = restaurantInfo.info.room_name;
    finalOrderJson.table_number = restaurantInfo.info.table_number;
    finalOrderJson.waiter_id = restaurantInfo.info.waiter_id;
    finalOrderJson.no_of_guest = restaurantInfo.noOfGuest;
    delete finalOrderJson.is_schedule_order;
    delete finalOrderJson.order_time;
  }
  const response = yield call(api.sendOrder, finalOrderJson);
  if (response.ok && response?.data?.status !== "Failure") {
    yield put(OrderActions.orderSuccessful());
    // toast.success("Order Successfully Placed");
    toast.success(response?.data?.status_message);
    yield put(push("/orderHistory"));
  } else {
    yield put(OrderActions.orderFail());
    toast.error(response?.data?.status_message);
    // toast.error("Order could not be placed, Please try again");
  }
}

export function* setDeliveryFee(api, action) {
  const { business_id, location_id, latitude, longitude } = action;

  const response = yield call(
    api.getDeliveryFee,
    business_id,
    location_id,
    latitude,
    longitude
  );

  if (response.ok) {
    if (response.ok) {
      const deliveryFee = {
        delivery_fee: response.data.delivery_fee,
        delivery_free_for_orders_above:
          response.data.delivery_free_for_orders_above,
        delivery_minimum_order_amount:
          response.data.delivery_minimum_order_amount,
      };
      yield put(OrderActions.successPhoneDeliveryFee(deliveryFee));
    } else yield put(OrderActions.errorPhoneDeliveryFee());
  }
}
