import React from "react";
import "./App.scss";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import AddressSelection from "./Screens/AddressSelection/AddressSelection";
import OrderScreen from "./Screens/OrderScreen/OrderScreen";
import Checkout from "./Screens/Checkout/Checkout";
import OrderHistory from "./Screens/OrderHistory/OrderHistory";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InitialPlaceholderScreen from "./Screens/InitialPlaceholderScreen";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ForgetPassword from "./Screens/ForgetPassword/ForgetPassword";
import PhoneLogin from "./Screens/PhoneLogin/PhoneLogin";
import PhoneInitialPlaceholderScreen from "./Screens/PhoneInitialPlaceholderScreen";
import WebMenuInitialPlaceholderScreen from "./Screens/WebMenuInitialPlaceholderScreen";
import DineInInitialPlaceholderScreen from "./Screens/DineInInitialPlaceholderScreen";
import { ConnectedRouter } from "connected-react-router";
import favicon from "./assets/images/favicon.ico";
import Favicon from "react-favicon";
import ReactGA from "react-ga";
ReactGA.initialize("UA-172681295-1");

toast.configure();

class App extends React.Component {
  constructor(props) {
    super(props);

    const theme = createMuiTheme({
      palette: {
        secondary: {
          main: "#fc6167",
          contrastText: "#ffffff",
        },
        primary: {
          main: "rgb(179, 177, 177)",
        },
      },
    });

    this.state = {
      theme: theme,
    };
  }

  changeThemeColor = (color) => {
    const theme = createMuiTheme({
      palette: {
        secondary: {
          main: color,
          contrastText: "#ffffff",
        },
        primary: {
          main: "rgb(179, 177, 177)",
        },
      },
    });
    this.setState({
      theme: theme,
    });
  };

  componentDidMount() {
    const { history } = this.props;

    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          ReactGA.pageview(newLocation.pathname);
          // Save new location
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }

  

  render() {

    const copyrighttext = `${new Date().getFullYear()}`

    return (
      <Router>
        <Favicon url={favicon} />
        <ThemeProvider theme={this.state.theme}>
          <ConnectedRouter history={this.props.history}>
            <Switch>
              <Route
                path="/web/:restaurant/:location"
                render={() => (
                  <InitialPlaceholderScreen
                    onThemeColorChange={this.changeThemeColor}
                  />
                )}
              />
              <Route
                path="/orderHistory"
                render={() => (
                  <OrderHistory onThemeColorChange={this.changeThemeColor} />
                )}
              />
              <Route
                path="/phone-order/:restaurant/:location"
                component={PhoneInitialPlaceholderScreen}
              />
              <Route
                path="/dine-in/:restaurant/:location/:roomName/:tableNumber"
                render={() => (
                  <DineInInitialPlaceholderScreen
                    onThemeColorChange={this.changeThemeColor}
                  />
                )}
              />
              <Route
                path="/web-menu/:restaurant/:location"
                render={() => (
                  <WebMenuInitialPlaceholderScreen
                    onThemeColorChange={this.changeThemeColor}
                  />
                )}
              />
              <Route
                exact
                path="/forgetPassword/:approveKey"
                render={() => (
                  <ForgetPassword onThemeColorChange={this.changeThemeColor} />
                )}
              />
            </Switch>
            <div className="footer-text">
              Copyright &copy; {copyrighttext} Order On The Go
              
            </div>
          </ConnectedRouter>
        </ThemeProvider>
      </Router>
    );
  }
}
export default App;
