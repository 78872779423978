import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

// Types and actions creator

const {Types, Creators} = createActions({
  setAll: ['orderJson', 'productsInfo', 'menu'],
  setOrder: ['orderJson'],
  setProductsInfo: ['productInfo'],
  setMenu: ['menu'],
  setOrderandProductsInfo: ['orderJson', 'productsInfo'],
  clearState: {},
  setHistoryFlag: ['flag'],
});

export const OrderHistoryStateTypes = Types;
export default Creators;

// Initial State
export const INITIAL_ORDER_HISTORY_STATE = Immutable({
  orderJson: {},
  productsInfo: {},
  menu: {},
  historyFlag: false,
});

// Selectors to derive data from state
export const OrderHistorySelectors = {
  getOrderJson: state => state.orderHistoryFlow.orderJson,
  getProductsInfo: state => state.orderHistoryFlow.productsInfo,
  getMenu: state => state.orderHistoryFlow.menu,
  getHistoryFlag: state => state.orderHistoryFlow.historyFlag,
};

// Reducers

export const setAll = (state, {orderJson, productsInfo, menu}) => {
  return {
    orderJson: orderJson,
    productsInfo: productsInfo,
    menu: menu,
    historyFlag: state.historyFlag,
  };
};

export const setMenu = (state, menu) => {
  return {
    orderJson: state.orderJson,
    productsInfo: state.productsInfo,
    menu: menu,
    historyFlag: state.historyFlag,
  };
};

export const setOrder = (state, orderJson) => {
  return {
    orderJson: orderJson,
    productsInfo: state.productsInfo,
    menu: state.menu,
    historyFlag: state.historyFlag,
  };
};

export const setProductsInfo = (state, productsInfo) => {
  return {
    orderJson: state.orderJson,
    productsInfo: productsInfo,
    menu: state.menu,
    historyFlag: state.historyFlag,
  };
};

export const setOrderandProductsInfo = (state, {orderJson, productsInfo}) => {
  return {
    orderJson: orderJson,
    productsInfo: productsInfo,
    menu: state.menu,
    historyFlag: state.historyFlag,
  };
};

export const clearState = state => {
  return {
    orderJson: {},
    productsInfo: {},
    menu: {},
  };
};

export const setHistoryFlag = (state, {flag}) => {
  return {
    orderJson: state.orderJson,
    productsInfo: state.productsInfo,
    menu: state.menu,
    historyFlag: flag,
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_ORDER_HISTORY_STATE, {
  [Types.SET_ALL]: setAll,
  [Types.SET_ORDER]: setOrder,
  [Types.SET_MENU]: setMenu,
  [Types.SET_PRODUCTS_INFO]: setProductsInfo,
  [Types.SET_ORDERAND_PRODUCTS_INFO]: setOrderandProductsInfo,
  [Types.CLEAR_STATE]: clearState,
  [Types.SET_HISTORY_FLAG]: setHistoryFlag,
});
