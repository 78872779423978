import React, { Component } from "react";
import { connect } from "react-redux";
import { MenuSelectors } from "../../Redux/MenuRedux";
import { LoginSelectors } from "../../Redux/LoginRedux";
import CustomerActions, { CustomerSelectors } from "../../Redux/CustomerRedux";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withTheme } from "@material-ui/core/styles";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../../Redux/StoreLoginRedux";
import {
  Grid,
  Card,
  Modal,
  Box,
  TextField,
  CardHeader,
  CardContent,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  InputAdornment,
  Hidden,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import moment from "moment-timezone";
// import styles from "./style.module.scss";
import styles from "./style.module.scss";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { discountTypeLookup } from "../../Lookups/discountTypeLookup";
import MenuApi from "../../Services/MenuApi";
import calculateTip from "../../Services/Calculations";
import Api from "../../Services/Api";
import LoginActions from "../../Redux/LoginRedux";
import Snackbar from "@material-ui/core/Snackbar";
import CreditCardInput from "react-credit-card-input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Remove from "@material-ui/icons/Remove";
import CancelIcon from "@material-ui/icons/Clear";
import Navbar from "../../components/Navbar/Navbar";
import createUrlToScreen from "../../Services/Utils";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import RedeemIcon from "@material-ui/icons/Redeem";
import Loading from "../../components/Loading/Loading";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import { toast } from "react-toastify";
import images from "react-payment-inputs/images";
import {
  PaymentInputsContainer,
  PaymentInputsWrapper,
} from "react-payment-inputs";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";

import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import loginApi from "../../Services/loginApi";

var valid = require("card-validator");

class CheckoutComponent extends Component {
  // constructor(props) {
  //     super(props);
  // }

  constructor(props) {
    super(props);
    // if (this.props.route.params) {
    //     this.state = {
    //         showPayment: false,
    //         selectedPayment:
    //             this.props.activeCustomer?.card_list.length > 0
    //                 ? this.getLastCardUsedIndex()
    //                 : 'Add Card',
    //         finalOrderJson: this.props.route.params.finalOrderJson,
    //         productsInfo: this.props.route.params.productsInfo,
    //         restaurantDetails: this.props.route.params.restaurantDetails,
    //         cardNumber: '',
    //         expDate: '',
    //         cardCvv: '',
    //         cardType: '',
    //         oldCardUsed: this.props.activeCustomer?.card_list.length > 0 ? true : false,
    //         orderSentLoading: false,
    //         tipIndex: 1,
    //         tipInputValue: '',
    //         showCoupon: false,
    //         couponInput: '',
    //         couponValid: true,
    //         couponInfoForReorder: {},
    //         orderInstructions: '',
    //         removeCouponHook: true,
    //     };
    // } else {
    this.state = {
      showPayment: false,
      selectedPayment:
        this.props.activeCustomer?.card_list?.length > 0
          ? this.getLastCardUsedIndex()
          : "Add Card",
      finalOrderJson: null,
      restaurantDetails: null,
      productsInfo: null,
      cardNumber: "",
      expDate: "",
      cardType: "",
      cardCvv: "",
      oldCardUsed:
        this.props.activeCustomer?.card_list?.length > 0 ? true : false,
      orderSentLoading: false,
      tipIndex: 4,
      tipInputValue: "",
      showCoupon: false,
      couponInput: "",
      couponValid: true,
      couponInfoForReorder: {},
      orderInstructions: "",
      removeCouponHook: true,
      showError: false,
      errorMessage: "",
      showloginModal: false,
      selectedCoupon: null,
      specialInstructionError: false,
      cardError: false,
      cardZip: "",
      zipError: false,
    };
    // }
  }

  zipCodeInputRef = React.createRef();

  componentDidMount() {
    this.props.onRef(this);
    this.setNoTip();
    // this.getLatestUserInfo();
    // TODO
    // this._subUserInfo = this.props.navigation.addListener(
    //     'focus',
    //     (payload) => {
    //         self.getLatestUserInfo();
    //     },
    // );
    // this._subCouponRemoval = this.props.navigation.addListener(
    //     'blur',
    //     (payload) => {
    //         self.removeCoupon();
    //     },
    // );
    // if (this.props.selectedProducts.length == 0) {
    //   this.props.history.push(createUrlToScreen("", this.props.match.url));
    // }
    this.setState({ selectedCoupon: null });
  }

  componentWillUnmount() {
    // if (this._subUserInfo.remove != undefined) {
    //     this._subUserInfo.remove();<
    // }
    // if (this._subCouponRemoval.remove != undefined) {
    //     this._subCouponRemoval.remove();
    // }
  }

  getLastCardUsedIndex = () => {
    let lastCardIndex = 0;
    if (this.props.activeCustomer?.card_list.length > 0) {
      this.props.activeCustomer.card_list.map((card, index) => {
        if (card.is_flag == 1) {
          lastCardIndex = index;
        }
      });
    }
    return lastCardIndex;
  };

  getLatestUserInfo = () => {
    if (this.props.isUserLoggedIn) {
      const apiService = loginApi.create();
      apiService
        .getLatestUserInfo(this.props.userInfo.customer_id, this.props.token)
        .then((response) => {
          if (response?.data?.status == "Success") {
            this.props.dispatch(
              LoginActions.setPastAddressAndCard(
                response.data.address_list,
                response.data.card_list
              )
            );
            if (response.data.card_list.length > 0) {
              this.setState({ selectedPayment: this.getLastCardUsedIndex() });
            }
          }
        });
    }
  };

  getVariationInfo = (product) => {
    if (product.variation_id) {
      return (
        " (" +
        this.props.variationList.find(
          (variation) => variation.variation_id == product.variation_id
        ).variation_name +
        ")"
      );
    }
    return "";
  };

  getProductName = (id) => {
    let menuItem = this.props.menuItems.filter((item) => item.item_id == id);
    return menuItem[0].item_name;
  };

  getModifierName = (id) => {
    let modifierItem = this.props.modifiers.filter(
      (item) => item.modifier_id == id
    );
    return modifierItem[0].modifier_name;
  };

  isAsapAvailable = (restaurantInfo) => {
    if (this.props.orderType == 1) return restaurantInfo.is_asap_delivery;
    else return restaurantInfo.is_asap_pickup;
  };

  isScheduleAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_scheduled_delivery;
    else return this.props.restaurantInfo?.info?.is_scheduled_pickup;
  };

  isPaymentDisabled = () => {
    var flag = true;
    if (this.state.selectedPayment == "Add Card") {
      var numberValidation = valid.number(this.state.cardNumber);
      var cvvValidation3 = valid.cvv(this.state.cardCvv);
      var cvvValidation4 = valid.cvv(this.state.cardCvv, 4);
      var expValidation = valid.expirationDate(this.state.expDate);
      var zipValidation = valid.postalCode(this.state.cardZip);
      if (
        numberValidation.isValid &&
        expValidation.isValid &&
        (cvvValidation3.isValid || cvvValidation4.isValid) &&
        zipValidation.isValid
      ) {
        flag = false;
      }
    } else {
      flag = false;
    }
    return flag;
  };

  savePayment = () => {
    if (this.state.selectedPayment == "Add Card") {
      var numberValidation = valid.number(this.state.cardNumber);
      var cvvValidation3 = valid.cvv(this.state.cardCvv);
      var cvvValidation4 = valid.cvv(this.state.cardCvv, 4);
      var expValidation = valid.expirationDate(this.state.expDate);
      var zipValidation = valid.postalCode(this.state.cardZip);
      if (
        numberValidation.isValid &&
        expValidation.isValid &&
        (cvvValidation3.isValid || cvvValidation4.isValid) &&
        zipValidation.isValid
      ) {
        this.setState({ showPayment: false });
      }
    } else {
      this.setState({ showPayment: false });
    }
  };

  isRestaurantAvailable = async () => {
    if (this.props.orderType == 2 || !this.props.isScheduled) {
      const apiServiceRestaurant = Api.create();
      return await apiServiceRestaurant
        .getRestaurantInfo(
          this.props.match?.params?.restaurant,
          this.props.match?.params?.location == "main"
            ? null
            : this.props.match?.params?.location
        )
        .then((response) => {
          if (response.status == 200 && response.data?.business_id) {
            if (this.props.orderType == 2) {
              if (!response.data?.is_pick_up_available) {
                return false;
              }
            }
            if (!this.props.isScheduled) {
              if (!this.isAsapAvailable(response.data)) {
                return false;
              }
            }
            if (this.props.isScheduled) {
              if (!this.isScheduleAvailable(response.data)) {
                return false;
              }
            }
            return true;
          } else {
            return false;
          }
        });
    } else {
      return true;
    }
  };

  sendOrder = async () => {
    if (!this.state.orderSentLoading) {
      this.props.orderSentLoading(true);
      this.setState({ orderSentLoading: true, removeCouponHook: false });
      const apiService = MenuApi.create();
      let payment_method = 2;
      let scheduledDate = "";
      if (this.props.isScheduled) {
        var momentDate = moment(
          this.props.scheduleDateTime,
          "YYYY-MM-DD HH:mm A"
        );

        scheduledDate = momentDate.format(
          moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
        );
      }

      // const restaurantAvailable = await this.isRestaurantAvailable();

      // Check the restaurant status if order is ASAP or Pickup
      // if (restaurantAvailable) {
      // Non - card processing flow
      if (
        typeof this.state.selectedPayment != "number" &&
        this.state.selectedPayment != "Add Card"
      ) {
        let payment_method = 4;
        if (this.state.selectedPayment == "Pay on Delivery - Card") {
          payment_method = 5;
        } else if (this.state.selectedPayment == "Pay on Delivery - Cash") {
          payment_method = 6;
        }

        const billTotal = this.state.finalOrderJson
          ? this.state.finalOrderJson.bill_total
          : this.props.totalCost;

        // New Order flow
        if (!this.state.finalOrderJson) {
          let selected_products = JSON.parse(
            JSON.stringify(this.props.selectedProducts)
          );
          selected_products.forEach((prod) => delete prod.taxInfo);

          var coupon_amount = this.isCouponApplied()
            ? (+this.getCouponValue()).toFixed(2)
            : "";

          let finalOrderJson = {
            customer_name: this.props.activeCustomer.customer_name,
            customer_phone: this.props.activeCustomer.customer_phone,
            customer_id: this.props.activeCustomer.customer_id,
            business_id: this.props.businessId,
            location_id: this.props.locationId,
            products: selected_products,
            order_type: this.props.orderType,
            sub_total: this.props.getSubTotal,
            original_subtotal: this.props.oldSubTotal,
            tax: this.props.totalTaxes,
            bill_total: billTotal,
            tax_list: this.props.getTotalTaxList,
            token: this.props.token,
            address: this.props.selectedAddress?.address,
            payment_type: payment_method,
            is_schedule_order: this.props.isScheduled ? 1 : 0,
            order_time: scheduledDate,
            tip_cost: this.props.tipValue,
            delivery_fee: this.getDeliveryFee(),
            coupon_id: this.props.couponInfo.applied
              ? this.props.couponInfo.name
              : "",
            coupon_amount: coupon_amount,
            instructions: this.state.orderInstructions,
            phone_order: 1,
          };

          // if (payment_method == 2) {
          //   finalOrderJson.ssl_approval_code = payment_info.ssl_approval_code;
          //   finalOrderJson.ssl_card_short_description =
          //     payment_info.ssl_card_short_description;
          //   finalOrderJson.ssl_card_number = payment_info.ssl_card_number;
          //   finalOrderJson.ssl_card_type = payment_info.ssl_card_type;
          //   finalOrderJson.ssl_txn_id = payment_info.ssl_txn_id;
          // }

          apiService.sendOrder(finalOrderJson).then((response) => {
            if (response.ok && response?.data?.status != "Failure") {
              this.props.dispatch(OrderActions.phoneOrderSuccessful());
              this.props.orderSentLoading(false);
              this.setState({
                selectedCoupon: null,
                orderInstructions: "",
                selectedPayment: "Add Card",
                orderSentLoading: false,
              });
              this.props.dispatch(CustomerActions.clearCustomers());
              this.props.dispatch(StoreLoginActions.clearUserData());
              toast.success("Order Successfully Placed");
            } else {
              this.props.dispatch(OrderActions.orderFail());
              toast.error("Failed");
            }
          });

          this.props.onPlaceOrder();

          // this.props.dispatch(
          //   OrderActions.placeOrder(
          //     payment_method,
          //     this.props.businessId,
          //     this.props.locationId,
          //     this.props.orderType,
          //     this.props.userInfo,
          //     this.props.selectedAddress,
          //     {},
          //     this.props.isScheduled,
          //     scheduledDate,
          //     this.getDeliveryFee(),
          //     this.state.orderInstructions,
          //     this.isCouponApplied()
          //       ? (+this.getCouponValue()).toFixed(2)
          //       : "",
          //     this.props.match.url
          //   )
          // );
          // setTimeout(() => this.setState({ orderSentLoading: false }), 2000);
        }
        // else {
        //     // Old order from history flow
        //     AsyncStorage.getItem('@player_id_notifications').then((id) => {

        //         let finalOrderJson = this.state.finalOfrderJson;
        //         if (id) {
        //             finalOrderJson.player_id = id;
        //         }
        //         delete finalOrderJson.receipt;
        //         delete finalOrderJson.coupon_id;

        //         finalOrderJson.customer_name = this.props.userInfo.customer_name;
        //         finalOrderJson.customer_phone = this.props.userInfo.customer_phone;
        //         finalOrderJson.customer_id = this.props.userInfo.customer_id;

        //         finalOrderJson.order_type = this.props.orderType;

        //         finalOrderJson.payment_type = payment_method;
        //         // Add the user token
        //         finalOrderJson.token = this.props.token;
        //         finalOrderJson.address = this.props.selectedAddress.address;

        //         // Add tip
        //         finalOrderJson.tip_cost = this.getTipAmountForReorderHistory();
        //         // Add delivery fee to total too
        //         finalOrderJson.bill_total = this.getTotalForReorderHistory();
        //         if (this.props.orderType == 1) {
        //             finalOrderJson.delivery_fee = this.getDeliveryFee();
        //         }
        //         // Add Order Schedule info
        //         finalOrderJson.is_schedule_order = this.props.isScheduled ? 1 : 0;
        //         finalOrderJson.order_time = scheduledDate;

        //         finalOrderJson.instructions = this.state.orderInstructions;
        //         finalOrderJson.products = finalOrderJson.product_list;
        //         delete finalOrderJson.product_list;
        //         if (
        //             this.state.couponInfoForReorder &&
        //             this.state.couponInfoForReorder.applied
        //         ) {
        //             finalOrderJson.coupon_id = this.state.couponInfoForReorder.name;
        //             finalOrderJson.coupon_amount = (+this.getCouponValue()).toFixed(
        //                 2,
        //             );
        //         }
        //         apiService.sendOrder(finalOrderJson).then((response) => {
        //             this.props.navigation.navigate('Order History');
        //         });
        //     });
        // }
      } else {
        // Card flow
        let payment_info = this.createPaymentObject();
        // New order flow
        if (!this.state.finalOrderJson) {
          const billTotal = this.state.finalOrderJson
            ? this.state.finalOrderJson.bill_total
            : this.props.totalCost;
          apiService.payOrder(payment_info).then((response) => {
            if (
              this.validateStatus(response.status) &&
              response.data.status == "Success"
            ) {
              response = response.data;

              let selected_products = JSON.parse(
                JSON.stringify(this.props.selectedProducts)
              );
              selected_products.forEach((prod) => delete prod.taxInfo);

              var coupon_amount = this.isCouponApplied()
                ? (+this.getCouponValue()).toFixed(2)
                : "";

              let finalOrderJson = {
                customer_name: this.props.activeCustomer.customer_name,
                customer_phone: this.props.activeCustomer.customer_phone,
                customer_id: this.props.activeCustomer.customer_id,
                business_id: this.props.businessId,
                location_id: this.props.locationId,
                products: selected_products,
                order_type: this.props.orderType,
                sub_total: this.props.getSubTotal,
                original_subtotal: this.props.oldSubTotal,
                tax: this.props.totalTaxes,
                bill_total: billTotal,
                tax_list: this.props.getTotalTaxList,
                token: this.props.token,
                address: this.props.selectedAddress?.address,
                payment_type: payment_method,
                is_schedule_order: this.props.isScheduled ? 1 : 0,
                order_time: scheduledDate,
                tip_cost: this.props.tipValue,
                delivery_fee: this.getDeliveryFee(),
                coupon_id: this.props.couponInfo.applied
                  ? this.props.couponInfo.name
                  : "",
                coupon_amount: coupon_amount,
                instructions: this.state.orderInstructions,
                phone_order: 1,
              };

              if (payment_method == 2) {
                finalOrderJson.ssl_approval_code = response.ssl_approval_code;
                finalOrderJson.ssl_card_short_description =
                  response.ssl_card_short_description;
                finalOrderJson.ssl_card_number = response.ssl_card_number;
                finalOrderJson.ssl_card_type = response.ssl_card_type;
                finalOrderJson.ssl_txn_id = response.ssl_txn_id;
              }

              apiService.sendOrder(finalOrderJson).then((response) => {
                if (response.ok && response?.data?.status != "Failure") {
                  this.props.dispatch(OrderActions.phoneOrderSuccessful());
                  this.props.orderSentLoading(false);
                  this.setState({
                    selectedCoupon: null,
                    orderInstructions: "",
                    selectedPayment: "Add Card",
                    orderSentLoading: false,
                  });
                  this.props.dispatch(CustomerActions.clearCustomers());
                  this.props.dispatch(StoreLoginActions.clearUserData());
                  toast.success("Order Successfully Placed");
                } else {
                  this.props.dispatch(OrderActions.orderFail());
                  toast.error("Failed");
                }
              });

              this.props.onPlaceOrder();
            } else {
              if (
                response.data.status == "Failure" &&
                response.data.status_message
              ) {
                toast.error(response.data.status_message);
              } else {
                toast.error("Payment Failed!");
              }
              this.props.orderSentLoading(false);
              this.setState({
                orderSentLoading: false,
              });
            }
          });
        }
        // else {
        //     // Old order from order history flow
        //     apiService.payOrder(paymentInfo).then((response) => {
        //         if (this.validateStatus(response.status)) {
        //             AsyncStorage.getItem('@player_id_notifications').then((id) => {
        //                 let finalOrderJson = this.state.finalOrderJson;
        //                 if (id) {
        //                     finalOrderJson.player_id = id;
        //                 }

        //                 delete finalOrderJson.receipt;
        //                 delete finalOrderJson.coupon_id;
        //                 delete finalOrderJson.payment_method;

        //                 finalOrderJson.customer_name = this.props.userInfo.customer_name;
        //                 finalOrderJson.customer_phone = this.props.userInfo.customer_phone;
        //                 finalOrderJson.customer_id = this.props.userInfo.customer_id;

        //                 finalOrderJson.order_type = this.props.orderType;
        //                 // Set payment info
        //                 finalOrderJson.payment_type = payment_method;
        //                 finalOrderJson.ssl_approval_code =
        //                     response.data.ssl_approval_code;
        //                 finalOrderJson.ssl_card_short_description =
        //                     response.data.ssl_card_short_description;
        //                 finalOrderJson.ssl_card_number = response.data.ssl_card_number;
        //                 finalOrderJson.ssl_card_type = response.data.ssl_card_type;
        //                 finalOrderJson.ssl_txn_id = response.data.ssl_txn_id;
        //                 // Add the user token
        //                 finalOrderJson.token = this.props.token;
        //                 finalOrderJson.address = this.props.selectedAddress.address;

        //                 // Add tip
        //                 finalOrderJson.tip_cost = this.getTipAmountForReorderHistory();
        //                 // Add delivery fee to total too
        //                 finalOrderJson.bill_total = this.getTotalForReorderHistory();
        //                 if (this.props.orderType == 1) {
        //                     finalOrderJson.delivery_fee = this.getDeliveryFee();
        //                 }
        //                 // Add Order Schedule info
        //                 finalOrderJson.is_schedule_order = this.props.isScheduled
        //                     ? 1
        //                     : 0;
        //                 finalOrderJson.order_time = scheduledDate;

        //                 finalOrderJson.products = finalOrderJson.product_list;
        //                 delete finalOrderJson.product_list;

        //                 if (
        //                     this.state.couponInfoForReorder &&
        //                     this.state.couponInfoForReorder.applied
        //                 ) {
        //                     finalOrderJson.coupon_id = this.state.couponInfoForReorder.name;
        //                     finalOrderJson.coupon_amount = (+this.getCouponValue()).toFixed(
        //                         2,
        //                     );
        //                 }
        //                 finalOrderJson.instructions = this.state.orderInstructions;

        //                 apiService.sendOrder(finalOrderJson).then((response) => {
        //                     this.props.navigation.navigate('Order History');
        //                 });
        //             });
        //         } else {
        //             this.setState({ orderSentLoading: false });
        //             showMessage({
        //                 message: 'Payment Failed! Please try again',
        //                 type: 'danger',
        //                 titleStyle: { textAlign: 'center' },
        //             });
        //         }
        //     });
        // }
      }
      // } else {
      //   // Go to Address Selection screen
      //   toast.error("Restaurant is not available at the moment");
      //   // this.props.history.push(createUrlToScreen("", this.props.match.url));
      // }
    }
  };

  validateStatus = (status) => {
    return status >= 200 && status < 300; // default
  };

  createPaymentObject = () => {
    let paymentInfo = {
      business_id: this.state.finalOrderJson
        ? this.state.finalOrderJson.business_id
        : this.props.businessId,
      location_id: this.state.finalOrderJson
        ? this.state.finalOrderJson.location_id
        : this.props.locationId,
      customer_name: this.props.activeCustomer?.customer_name,
      customer_phone: this.props.activeCustomer?.customer_phone,
      customer_id: this.props.activeCustomer?.customer_id,
      bill_total: this.state.finalOrderJson
        ? this.state.finalOrderJson.bill_total
        : this.props.totalCost,
      token: this.props.token,
      phone_order: 1,
    };

    if (
      this.state.selectedPayment !== "Add Card" &&
      typeof this.state.selectedPayment == "number"
    ) {
      paymentInfo.ssl_token =
        this.props.activeCustomer?.card_list[
          this.state.selectedPayment
        ].ssl_token;
      paymentInfo.ssl_card_number =
        this.props.activeCustomer?.card_list[
          this.state.selectedPayment
        ]?.ssl_card_number;
      paymentInfo.card_name =
        this.props.activeCustomer?.card_list[
          this.state.selectedPayment
        ].card_name;
    } else {
      paymentInfo.card_number = this.state.cardNumber.replace(/\s+/g, "");
      paymentInfo.expire_month = this.state.expDate.substring(0, 2);
      paymentInfo.expire_year = this.state.expDate.substring(5, 7);
      paymentInfo.card_postal_code = this.state.cardZip;
      paymentInfo.cvv = this.state.cardCvv;
    }
    return paymentInfo;
  };

  displayProducts = () => {
    if (this.props.selectedProducts.length > 0) {
      return this.props.selectedProducts.map((product, index) => (
        <li key={product.product_id + "v" + index}>
          {product.quantity +
            " - " +
            this.getProductName(product.product_id) +
            this.getVariationInfo(product) +
            " = " +
            "$" +
            (+product.original_total).toFixed(2)}
        </li>
        // <Fragment key={product.product_id + 'v'}>
        //     <ListItem
        //         containerStyle={{ paddingRight: 0, paddingLeft: 0 }}
        //         leftElement={
        //             <span style={styles.productQuantity}>{product.quantity}</span>
        //         }
        //         title={
        //             this.getProductName(product.product_id) +
        //             this.getVariationInfo(product)
        //         }
        //         rightTitle={'$' + (+product.original_total).toFixed(2)}
        //         subtitle={
        //             product.modifiers.length > 0 ? (
        //                 <div style={styles.modifiersContainer}>
        //                     {product.modifiers.map((modifier) => (
        //                         <span>{this.getModifierName(modifier.modifier_id)}</span>
        //                     ))}
        //                 </div>
        //             ) : null
        //         }
        //     />
        // </Fragment>
      ));
    } else {
      return (
        <div>
          <span>Add Products to order</span>
        </div>
      );
    }
  };

  isTipEnabled = () => {
    if (this.state.finalOrderJson) {
      return this.state.restaurantDetails.is_tip_available == 1;
    } else {
      return (
        this.props.restaurantInfo.business_id != "" &&
        this.props.restaurantInfo.info.is_tip_available == 1
      );
    }
  };

  isPayAtStoreEnabled = () => {
    if (this.props.orderType == 2) {
      if (this.state.finalOrderJson) {
        return this.state.restaurantDetails.is_pay_at_store_available == 1;
      } else {
        return (
          this.props.restaurantInfo.business_id != "" &&
          this.props.restaurantInfo.info.is_pay_at_store_available == 1
        );
      }
    } else {
      return false;
    }
  };
  isPayOnDeliveryCashEnabled = () => {
    if (this.props.orderType == 1) {
      if (this.state.finalOrderJson) {
        return (
          this.state.restaurantDetails.is_pay_on_delivery_cash_available == 1
        );
      } else {
        return (
          this.props.restaurantInfo.business_id != "" &&
          this.props.restaurantInfo.info.is_pay_on_delivery_cash_available == 1
        );
      }
    } else {
      return false;
    }
  };

  isPayOnDeliveryCardEnabled = () => {
    if (this.props.orderType == 1) {
      if (this.state.finalOrderJson) {
        return (
          this.state.restaurantDetails.is_pay_on_delivery_card_available == 1
        );
      } else {
        return (
          this.props.restaurantInfo.business_id != "" &&
          this.props.restaurantInfo.info.is_pay_on_delivery_card_available == 1
        );
      }
    } else {
      return false;
    }
  };

  onPaymentInfoChange = (form) => {
    if (
      form.status.number == "valid" &&
      form.status.expiry == "valid" &&
      form.status.cvc == "valid"
    ) {
      this.setState({
        cardNumber: form.values.number.replace(/\s/g, ""),
        expDate: form.values.expiry,
        cardCvv: form.values.cvc,
        cardType: form.values.type,
      });
    } else {
      this.setState({ cardNumber: "", cardCvv: "", expDate: "", cardType: "" });
    }
  };

  isSubTotalDeliveryMinimumCompareError = () => {
    if (this.props.orderType == 1) {
      if (this.state.finalOrderJson) {
        if (
          this.state.finalOrderJson.sub_total <
          +this.state.restaurantDetails.delivery_minimum_order_amount
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        this.props.restaurantInfo.business_id != "" &&
        this.props.phoneDeliveryFee
      ) {
        if (
          this.props.subTotal <
          +this.props.phoneDeliveryFee.delivery_minimum_order_amount
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  isOrderDisable = () => {
    let isDisabled = false;
    isDisabled = this.isSubTotalDeliveryMinimumCompareError();
    if (!isDisabled) {
      if (this.state.selectedPayment !== null) {
        if (this.state.selectedPayment == "Add Card") {
          var numberValidation = valid.number(this.state.cardNumber);
          var cvvValidation3 = valid.cvv(this.state.cardCvv);
          var cvvValidation4 = valid.cvv(this.state.cardCvv, 4);
          var expValidation = valid.expirationDate(this.state.expDate);
          if (
            !(
              numberValidation.isValid &&
              expValidation.isValid &&
              (cvvValidation3.isValid || cvvValidation4.isValid)
            )
          ) {
            isDisabled = true;
          } else {
          }
        } else {
          isDisabled = false;
        }
      } else isDisabled = true;

      if (!isDisabled) {
        if (this.props.selectedProducts.length > 0) {
          isDisabled = false;
        } else {
          isDisabled = true;
        }
      }
    }

    if (this.state.specialInstructionError) {
      isDisabled = true;
    }

    return isDisabled;
  };

  changeTip = (selectedIndex) => {
    this.setState({
      tipIndex: selectedIndex,
      tipInputValue:
        selectedIndex == 3
          ? ""
          : this.state.finalOrderJson
          ? calculateTip(selectedIndex, +this.state.finalOrderJson.sub_total)
          : calculateTip(selectedIndex, this.props.subTotal),
    });
    if (!this.state.finalOrderJson) {
      this.props.dispatch(OrderActions.setTipIndex(selectedIndex));
    }
  };

  tipinputComponent = () => (
    <TextField
      id="outlined-basic"
      label="Enter amount"
      variant="outlined"
      onChange={(tipInput) => {
        this.setState({ tipInputValue: tipInput.target.value, tipIndex: 3 });
        this.props.dispatch(
          OrderActions.setTipInputValue(tipInput.target.value)
        );
      }}
    />
    // <Input
    //     keyboardType="numeric"
    //     placeholderTextColor="#a6a6a6"
    //     onFocus={() => {
    //         this.setState({ tipIndex: 3, tipInputValue: '' });
    //     }}
    //     inputStyle={styles.tipInputStyle}
    //     value={
    //         this.state.tipIndex == 3
    //             ? this.state.tipInputValue.toString()
    //             : this.state.finalOrderJson
    //                 ? calculateTip(
    //                     +this.state.tipIndex,
    //                     +this.state.finalOrderJson.sub_total,
    //                 )
    //                 : this.props.tipValue
    //     }
    //     leftIcon={
    //         <FontAwesome5Icon name="dollar-sign" style={{ color: '#a6a6a6' }} />
    //     }
    //     leftIconContainerStyle={{ paddingRight: 5 }}
    //     onChangeText={(tipInput) => {
    //         this.setState({ tipInputValue: tipInput, tipIndex: 3 });
    //         this.props.dispatch(OrderActions.setTipInputValue(tipInput));
    //     }}
    // />
  );

  getTipAmountForReorderHistory = () => {
    // Add tip available check here
    if (this.state.restaurantDetails.is_tip_available == 1) {
      if (this.state.tipIndex != 3) {
        return calculateTip(
          this.state.tipIndex,
          +this.state.finalOrderJson.sub_total
        );
      } else {
        return (+this.state.tipInputValue).toFixed(2);
      }
    } else {
      return 0.0;
    }
  };

  applyCoupon = (couponCode = null) => {
    if (couponCode) {
      if (this.state.finalOrderJson) {
      } else {
        let apiService = Api.create();
        apiService
          .checkCoupon(
            couponCode,
            this.props.businessId,
            this.props.locationId,
            this.props.token
          )
          .then((response) => {
            if (response.data.status != "Failure") {
              this.props.dispatch(
                OrderActions.applyCoupon(
                  response.data.coupon_type,
                  response.data.coupon_value,
                  this.props.tax_list,
                  couponCode
                )
              );
              this.setState({ showCoupon: false, couponInput: "" });
            } else if (response.data.status == "Failure") {
              toast.error(response.data.status_message);
              this.setState({ selectedCoupon: null });
            } else {
              toast.error(response.data.status_message);
              this.setState({ selectedCoupon: null });
            }
          });
      }
    }
  };

  removeCoupon = () => {
    if (this.isCouponApplied()) {
      if (this.state.finalOrderJson) {
      } else {
        this.props.dispatch(OrderActions.removeCoupon(this.props.tax_list));
        this.setState({ selectedCoupon: null });
      }
    }
  };

  getDiscountTotal = (discount_product_values) => {
    let totalDiscount = 0;
    discount_product_values.forEach((prod) => {
      totalDiscount += +(prod.oldValue - prod.newValue);
    });
    return totalDiscount.toFixed(2);
  };

  getTotalForReorderHistory = () => {
    let total = +this.state.finalOrderJson.bill_total;
    if (this.state.restaurantDetails.is_tip_available == 1) {
      if (this.getTipAmountForReorderHistory() > 0) {
        total += +this.getTipAmountForReorderHistory();
      }
    }
    if (this.props.orderType != 2) {
      total += +this.getDeliveryFee();
    }
    return (+total).toFixed(2);
  };

  getDeliveryFee = () => {
    const delivery_free = "0.00";
    if (this.props.phoneDeliveryFee) {
      if (this.props.orderType == 2) {
        return (+delivery_free).toFixed(2);
      }
      if (this.state.finalOrderJson) {
        if (
          +this.state.restaurantDetails.delivery_free_for_orders_above == 0 ||
          +this.state.finalOrderJson.sub_total <
            this.state.restaurantDetails.delivery_free_for_orders_above
        ) {
          return (+this.state.restaurantDetails.delivery_fee).toFixed(2);
        } else {
          return (+delivery_free).toFixed(2);
        }
      } else {
        if (this.props.restaurantInfo.business_id != "") {
          if (
            +this.props.phoneDeliveryFee.delivery_free_for_orders_above == 0 ||
            +this.props.subTotal <
              this.props.phoneDeliveryFee.delivery_free_for_orders_above
          ) {
            return (+this.props.phoneDeliveryFee.delivery_fee).toFixed(2);
          } else {
            return (+delivery_free).toFixed(2);
          }
        } else {
          return (+delivery_free).toFixed(2);
        }
      }
    } else {
      return (+delivery_free).toFixed(2);
    }
  };

  isFreeDeliveryNotAvailable = () => {
    if (this.state.finalOrderJson) {
      return +this.state.restaurantDetails.delivery_free_for_orders_above == 0;
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        return (
          +this.props.restaurantInfo.info.delivery_free_for_orders_above == 0
        );
      } else {
        return false;
      }
    }
  };

  isSubTotalLessThanFreeDeliveryAmount = () => {
    if (this.state.finalOrderJson) {
      return (
        +this.state.restaurantDetails.delivery_free_for_orders_above == 0 ||
        +this.state.finalOrderJson.sub_total <
          this.state.restaurantDetails.delivery_free_for_orders_above
      );
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        return (
          +this.props.restaurantInfo.info.delivery_free_for_orders_above > 0 ||
          this.props.subTotal <
            +this.props.restaurantInfo.info.delivery_free_for_orders_above
        );
      } else {
        return false;
      }
    }
  };

  getMoreOrderValueForFreeDelivery = () => {
    if (this.state.finalOrderJson) {
      return (
        +this.state.restaurantDetails.delivery_free_for_orders_above -
        this.state.finalOrderJson.sub_total
      ).toFixed(2);
    } else {
      return (
        +this.props.restaurantInfo.info.delivery_free_for_orders_above -
        this.props.subTotal
      ).toFixed(2);
    }
  };

  openPrivacy = () => {
    let url = "";
    if (this.state.finalOrderJson) {
      url = this.state.restaurantDetails.privacy_url;
    } else {
      if (this.props.restaurantInfo.business_id != "") {
        url = this.props.restaurantInfo.info.privacy_url;
      }
    }
    if (url != "") {
      // TODO
      // Linking.openURL(url);
    }
  };

  openTerms = () => {
    let url = "";
    if (this.state.finalOrderJson) {
      url = this.state.restaurantDetails.terms_url;
    } else {
      if (this.props.restaurantInfo.business_id !== "") {
        url = this.props.restaurantInfo.info.terms_url;
      }
    }
    if (url !== "") {
      // code here
    }
  };

  isCouponApplied = () => {
    if (this.state.finalOrderJson) {
      return (
        this.state.couponInfoForReorder &&
        this.state.couponInfoForReorder.applied
      );
    } else {
      return this.props.couponInfo.applied;
    }
  };

  getCouponValue = () => {
    if (this.state.finalOrderJson) {
      if (this.state.couponInfoForReorder != {}) {
        return this.state.couponInfoForReorder.type ==
          discountTypeLookup.VALUE_BASED
          ? this.state.couponInfoForReorder.value
          : this.getDiscountTotal(
              this.state.couponInfoForReorder.discount_product_values
            );
      }
    } else {
      return this.props.couponInfo.type == discountTypeLookup.VALUE_BASED
        ? this.props.couponInfo.value
        : this.getDiscountTotal(this.props.couponInfo.discount_product_values);
    }
  };

  getCouponDisplayInfo = () => {
    if (this.state.finalOrderJson) {
      if (this.state.couponInfoForReorder != {}) {
        return (
          this.state.couponInfoForReorder.name +
          "(" +
          this.state.couponInfoForReorder.value +
          (this.state.couponInfoForReorder.type ==
          discountTypeLookup.VALUE_BASED
            ? "$"
            : "%") +
          ")"
        );
      }
    } else {
      return (
        this.props.couponInfo.name +
        "(" +
        this.props.couponInfo.value +
        (this.props.couponInfo.type == discountTypeLookup.VALUE_BASED
          ? "$"
          : "%") +
        ")"
      );
    }
  };

  getMinimumDeliveryAmount = () => {
    if (this.state.finalOrderJson) {
      return this.state.restaurantDetails.delivery_minimum_order_amount;
    } else {
      if (
        this.props.restaurantInfo.business_id != "" &&
        this.props.phoneDeliveryFee
      )
        return this.props.phoneDeliveryFee.delivery_minimum_order_amount;
      else return 0;
    }
  };

  handleCouponChange = (event) => {
    if (event.target.value) {
      const coupon = JSON.parse(event.target.value);
      this.setState({ selectedCoupon: event.target.value });
      this.applyCoupon(coupon.coupon_code);
    } else {
      this.setState({ selectedCoupon: event.target.value });
      this.removeCoupon();
    }
  };

  closeCoupon = () => {
    this.setState({
      showCoupon: false,
      couponValid: true,
      couponInput: "",
    });
  };

  onCreditCardError = (inputName, error) => {};

  getSelectedPaymentText = () => {
    if (this.state.selectedPayment == "Add Card") {
      if (this.state.cardNumber == "") {
        return "";
      } else {
        let maskedCardNumber = this.state.cardNumber;
        // if (!this.state.cardError) {
        return (
          <div>
            <span style={{ paddingRight: 10, verticalAlign: "middle" }}>
              {this.state.cardType == "visa" && (
                <FontAwesomeIcon icon={faCcVisa} />
              )}
              {this.state.cardType == "mastercard" && (
                <FontAwesomeIcon icon={faCcMastercard} />
              )}
              {this.state.cardType == "american-express" && (
                <FontAwesomeIcon icon={faCcAmex} />
              )}
              {this.state.cardType !== "visa" &&
                this.state.cardType !== "mastercard" &&
                this.state.cardType !== "american-express" && (
                  <FontAwesomeIcon icon={faCreditCard} />
                )}
            </span>
            <span>
              {maskedCardNumber[0] +
                "*".repeat(maskedCardNumber.length == 20 ? 15 : 14) +
                maskedCardNumber[maskedCardNumber.length - 4] +
                maskedCardNumber[maskedCardNumber.length - 3] +
                maskedCardNumber[maskedCardNumber.length - 2] +
                maskedCardNumber[maskedCardNumber.length - 1]}
            </span>
          </div>
        );
        // } else {
        //   return "Card has error! Please try again";
        // }
      }
    } else if (typeof this.state.selectedPayment != "number") {
      return (
        <div>
          <span style={{ paddingRight: 8 }}>
            {this.state.cardType == "visa" && (
              <FontAwesomeIcon icon={faCcVisa} />
            )}
            {this.state.cardType == "mastercard" && (
              <FontAwesomeIcon icon={faCcMastercard} />
            )}
            {this.state.cardType == "american-express" && (
              <FontAwesomeIcon icon={faCcAmex} />
            )}
            {this.state.cardType !== "visa" &&
              this.state.cardType !== "mastercard" &&
              this.state.cardType !== "american-express" && (
                <FontAwesomeIcon icon={faCreditCard} />
              )}
          </span>
          <span>{this.state.selectedPayment}</span>
        </div>
      );
    } else if (typeof this.state.selectedPayment == "number") {
      return (
        <div>
          {this.props.activeCustomer?.card_list?.length > 0 && (
            <div>
              <span style={{ paddingRight: 8 }}>
                {this.props.activeCustomer?.card_list[
                  this.state.selectedPayment
                ].card_name == "visa" && <FontAwesomeIcon icon={faCcVisa} />}
                {this.props.activeCustomer?.card_list[
                  this.state.selectedPayment
                ].card_name == "mastercard" && (
                  <FontAwesomeIcon icon={faCcMastercard} />
                )}
                {this.props.activeCustomer?.card_list[
                  this.state.selectedPayment
                ].card_name == "american-express" && (
                  <FontAwesomeIcon icon={faCcAmex} />
                )}
                {this.props.activeCustomer?.card_list[
                  this.state.selectedPayment
                ].card_name !== "visa" &&
                  this.props.activeCustomer?.card_list[
                    this.state.selectedPayment
                  ].card_name !== "mastercard" &&
                  this.props.activeCustomer?.card_list[
                    this.state.selectedPayment
                  ].card_name !== "american-express" && (
                    <FontAwesomeIcon icon={faCreditCard} />
                  )}
              </span>
              <span>
                {
                  this.props.activeCustomer?.card_list[
                    this.state.selectedPayment
                  ].ssl_card_number
                }
              </span>
            </div>
          )}
        </div>
      );
    } else {
      return <div>{this.state.selectedPayment}</div>;
    }
  };

  setNoTip = () => {
    this.setState({
      tipIndex: 4,
      tipInputValue: "0.00",
    });
    this.props.dispatch(OrderActions.setTipInputValue(0));
    this.props.dispatch(OrderActions.setTipIndex(4));
  };

  handleChangeCardNumber = (cardNumber) => {
    var cardTypeArray = creditCardType(cardNumber.target.value);
    var cardType = cardTypeArray[0]?.type;
    this.setState({
      cardNumber: cardNumber.target.value,
      cardType: cardType,
    });
  };

  handleChangeExpiryDate = (expiryDate) => {
    this.setState({ expDate: expiryDate.target.value });
  };

  handleChangeCVC = (cvv) => {
    this.setState({ cardCvv: cvv.target.value });
    let length = 3;
    if (this.state.cardType == "american-express") {
      length = 4;
    }

    if (length == cvv.target.value.length) {
      this.zipCodeInputRef.focus();
    }
  };

  handleZipCodeChange = (zip) => {
    var zipValidation = valid.postalCode(zip.target.value);
    if (!zipValidation.isValid) {
      this.setState({ cardZip: zip.target.value, zipError: true });
    } else {
      this.setState({ cardZip: zip.target.value, zipError: false });
    }
  };

  handleZipCodeBlur = () => {
    if (this.state.selectedPayment == "Add Card") {
      var zipValidation = valid.postalCode(this.state.cardZip);
      if (!zipValidation.isValid) {
        this.setState({ zipError: true });
      } else {
        this.setState({ zipError: false });
      }
    }
  };

  render() {
    if (
      this.props.editModalUpdated &&
      this.state.selectedPayment != "Add Card"
    ) {
      this.setState({
        selectedPayment: "Add Card",
        tipIndex: 4,
        tipInputValue: "0.00",
        selectedCoupon: null,
      });
    }

    return (
      <>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box classes={{ root: styles.instruction }}>
              <TextField
                variant="standard"
                label="Instructions"
                helperText="Provide any additional information for your order (eg. APT#, Buzz
                  Code)"
                fullWidth={true}
                value={this.state.orderInstructions}
                onChange={(event) => {
                  var regex = new RegExp("^[a-zA-Z0-9# .,_ ]+$");
                  if (regex.test(event.target.value) || !event.target.value) {
                    this.setState({
                      orderInstructions: event.target.value,
                      specialInstructionError: false,
                    });
                  } else {
                    this.setState({
                      orderInstructions: event.target.value,
                      specialInstructionError: true,
                    });
                  }
                }}
              ></TextField>{" "}
              {this.state.specialInstructionError && (
                <div className={styles.specialInstructionError}>
                  Special Characters are not allowed
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} alignItems="flex-end"></Grid>
        </Grid>
        <Divider style={{ marginTop: "20px" }} />
        {this.isTipEnabled() && (
          <Grid item xs={12} classes={{ root: styles.commonPadding }}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={`font16Bold`}>Add Tip</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container justify="space-around">
                  <Button
                    color="primary"
                    variant={this.state.tipIndex == 4 ? "contained" : "text"}
                    size="small"
                    onClick={() => this.setNoTip()}
                  >
                    No Tip
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => this.changeTip(0)}
                    variant={this.state.tipIndex == 0 ? "contained" : "text"}
                  >
                    10%
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => this.changeTip(1)}
                    variant={this.state.tipIndex == 1 ? "contained" : "text"}
                  >
                    15%
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => this.changeTip(2)}
                    variant={this.state.tipIndex == 2 ? "contained" : "text"}
                  >
                    20%
                  </Button>
                  {/* <Button
                              onClick={() =>
                                this.setState({
                                  tipIndex: 3,
                                  tipInputValue: "",
                                })
                              }
                              size="small"
                            >
                              <TextField
                                variant="standard"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoneyIcon fontSize="inherit" />
                                    </InputAdornment>
                                  ),
                                }}
                                value={`${
                                  this.state.tipIndex == 3
                                    ? this.state.tipInputValue.toString()
                                    : this.state.finalOrderJson
                                      ? calculateTip(
                                        +this.state.tipIndex,
                                        +this.state.finalOrderJson.sub_total
                                      )
                                      : this.props.tipValue
                                  }`}
                                onChange={(tipInput) => {
                                  this.setState({
                                    tipInputValue: tipInput.target.value,
                                    tipIndex: 3,
                                  });
                                  this.props.dispatch(
                                    OrderActions.setTipInputValue(
                                      tipInput.target.value
                                    )
                                  );
                                }}
                              />
                            </Button> */}
                </Grid>
                {/* <ButtonGroup
                            variant="text"
                            color="primary"
                            fullWidth={true}
                            aria-label="text primary button group"
                          >
                            <Button
                              color="primary"
                              variant={
                                this.state.tipIndex == 4 ? "contained" : "text"
                              }
                              onClick={() => {
                                this.setState({
                                  tipIndex: 4,
                                  tipInputValue: "0.00",
                                });
                                this.props.dispatch(
                                  OrderActions.setTipInputValue(0)
                                );
                              }}
                            >
                              No Tip
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.changeTip(0)}
                              variant={
                                this.state.tipIndex == 0 ? "contained" : "text"
                              }
                            >
                              10%
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.changeTip(1)}
                              variant={
                                this.state.tipIndex == 1 ? "contained" : "text"
                              }
                            >
                              15%
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => this.changeTip(2)}
                              variant={
                                this.state.tipIndex == 2 ? "contained" : "text"
                              }
                            >
                              20%
                            </Button>
                            <Button
                              onClick={() =>
                                this.setState({
                                  tipIndex: 3,
                                  tipInputValue: "",
                                })
                              }
                            >
                              <TextField
                                variant="standard"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoneyIcon fontSize="inherit" />
                                    </InputAdornment>
                                  ),
                                }}
                                value={`${
                                  this.state.tipIndex == 3
                                    ? this.state.tipInputValue.toString()
                                    : this.state.finalOrderJson
                                    ? calculateTip(
                                        +this.state.tipIndex,
                                        +this.state.finalOrderJson.sub_total
                                      )
                                    : this.props.tipValue
                                }`}
                                onChange={(tipInput) => {
                                  this.setState({
                                    tipInputValue: tipInput.target.value,
                                    tipIndex: 3,
                                  });
                                  this.props.dispatch(
                                    OrderActions.setTipInputValue(
                                      tipInput.target.value
                                    )
                                  );
                                }}
                              />
                            </Button>
                          </ButtonGroup> */}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider style={{ marginTop: "20px" }} />
          <List classes={{ root: styles.commonPadding }}>
            <ListItem
              classes={{ root: styles.noInsideBorder }}
              button
              onClick={() => this.setState({ showPayment: true })}
            >
              <ListItemText
                primary={
                  this.state.selectedPayment == "Add Card"
                    ? this.state.cardNumber
                      ? "Payment Method"
                      : "Add Payment"
                    : "Payment Method"
                }
                secondary={this.getSelectedPaymentText()}
              />
              <ListItemSecondaryAction
                onClick={() => this.setState({ showPayment: true })}
              >
                {this.state.selectedPayment == "Add Card" ? (
                  this.state.cardNumber ? (
                    <CreditCardIcon />
                  ) : (
                    <CreditCardIcon />
                  )
                ) : (
                  <CreditCardIcon />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <List classes={{ root: styles.commonPadding }}>
            {!this.isCouponApplied() && (
              <FormControl fullWidth={true}>
                <InputLabel id="demo-simple-select-label">
                  Select Coupon
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.selectedCoupon}
                  onChange={this.handleCouponChange}
                >
                  <MenuItem value="">No Coupon Applied</MenuItem>
                  {this.props.restaurantInfo?.info?.coupon_list?.map(
                    (coupon) => {
                      return (
                        <MenuItem value={JSON.stringify(coupon)}>
                          {coupon.coupon_code} : {coupon.coupon_description}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
              // <ListItem
              //   classes={{ root: styles.noInsideBorder }}
              //   button
              //   onClick={() => this.setState({ showCoupon: true })}
              // >
              //   <ListItemText primary="Add Coupon" />
              //   <ListItemSecondaryAction
              //     onClick={() => this.setState({ showCoupon: true })}
              //   >
              //     <RedeemIcon />
              //   </ListItemSecondaryAction>
              // </ListItem>
            )}
            {this.isCouponApplied() && (
              <ListItem button classes={{ root: styles.noInsideBorder }}>
                <ListItemText
                  primary={"Coupon Applied : " + this.getCouponDisplayInfo()}
                />
                <ListItemSecondaryAction onClick={this.removeCoupon}>
                  <Remove />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div className={styles.totalPadding}>
            <Grid container direction="row" justify="space-between">
              <span className={styles.infoText}>Subtotal</span>
              <span className={styles.infoText}>
                {"$" + this.props.oldSubTotal}
              </span>
            </Grid>
            {this.props.orderType == 1 && (
              <Grid container direction="row" justify="space-between">
                <span className={styles.infoText}>Delivery Fee</span>
                <span className={styles.infoText}>
                  {"$" + this.getDeliveryFee()}
                </span>
              </Grid>
            )}
            {this.props.orderType == 1 &&
              this.isSubTotalLessThanFreeDeliveryAmount() && (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  classes={{ root: styles.padding16 }}
                >
                  <span className={`${styles.infoText} subhead`}>
                    Add food worth $ {this.getMoreOrderValueForFreeDelivery()}{" "}
                    and get free delivery
                  </span>
                </Grid>
              )}
            {this.isCouponApplied() && (
              <Grid container direction="row" justify="space-between">
                <span className={styles.discountText}>Discount</span>
                <span className={styles.discountText}>
                  {"- $" + (+this.getCouponValue()).toFixed(2)}
                </span>
              </Grid>
            )}
            {
              <Grid container direction="row" justify="space-between">
                <span className={styles.infoText}>Tax</span>
                <span className={styles.infoText}>
                  {" "}
                  $
                  {this.state.finalOrderJson
                    ? (+this.state.finalOrderJson.tax).toFixed(2)
                    : this.props.totalTaxes}
                </span>
              </Grid>
            }
            {/* TODO check tip enabled */}
            {this.isTipEnabled() && (
              <Grid container direction="row" justify="space-between">
                <span className={styles.infoText}>Tip</span>
                <span className={styles.infoText}>
                  {" "}
                  {"$" +
                    (this.state.finalOrderJson
                      ? this.getTipAmountForReorderHistory()
                      : (+this.props.tipValue).toFixed(2))}
                </span>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider className={styles.lastDivBeforeTotal} />
          <List classes={{ root: styles.listItemPadding }}>
            <ListItem classes={{ root: styles.noInsideBorder }}>
              <ListItemText
                classes={{ root: styles.totalTitleText }}
                primary="Total"
              />
              <ListItemText classes={{ root: styles.totalText }}>
                {"$" +
                  (this.state.finalOrderJson
                    ? this.getTotalForReorderHistory()
                    : this.props.totalCost)}
              </ListItemText>
            </ListItem>
            {this.isSubTotalDeliveryMinimumCompareError() && (
              <ListItem classes={{ root: styles.noInsideBorder }}>
                <ListItemText
                  className={styles.errorText}
                  primary={
                    "Minimum order of $" +
                    this.getMinimumDeliveryAmount() +
                    " required for delivery"
                  }
                />
              </ListItem>
            )}
          </List>
          {/* <Button
                        className={
                          this.isOrderDisable()
                            ? styles.orderDisabledButton
                            : styles.orderButton
                        }
                        disabled={this.isOrderDisable()}
                        onClick={() => this.sendOrder()}
                      >
                        Place Order
                      </Button>
                    </CardContent> */}
        </Grid>

        <Grid item xs={12} sm={12} style={{ width: "100%" }}>
          <Button
            className={
              this.isOrderDisable()
                ? styles.orderDisabledButton
                : styles.orderButton
            }
            style={
              !this.isOrderDisable()
                ? {
                    backgroundColor: this.props.restaurantDetails?.themeColor
                      ? this.props.restaurantDetails?.themeColor
                      : "#fc6167",
                  }
                : {}
            }
            disabled={this.isOrderDisable()}
            onClick={() => this.sendOrder()}
          >
            Place Order
          </Button>
        </Grid>
        <Dialog
          open={this.state.showCoupon}
          disableBackdropClick
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <span style={{ paddingTop: "5px" }}>Apply Coupon</span>
            <Button
              onClick={() => this.setState({ showCoupon: false })}
              style={{ float: "right", paddingRight: 0 }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent className={styles.couponDialog}>
            <TextField
              style={{ width: "100%", marginBottom: "20px" }}
              autoFocus
              margin="dense"
              id="coupon"
              label="Coupon Code"
              error={!this.state.couponValid}
              onChange={(value) =>
                this.setState({ couponInput: value.target.value })
              }
              value={this.state.couponInput}
              helperText={!this.state.couponValid ? "Enter valid coupon" : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.applyCoupon()}
              color="primary"
              className={styles.orderButton}
            >
              Apply Coupon
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showPayment}
          disableBackdropClick
          aria-labelledby="form-dialog-title"
          classes={{
            root: styles.dialogContainer,
            paperScrollPaper: styles.customMobileDialogContainer,
          }}
        >
          <DialogTitle id="form-dialog-title">Select Payment</DialogTitle>
          <DialogContent className={styles.paymentDialog}>
            <RadioGroup aria-label="payment" name="payment">
              {this.props.activeCustomer?.card_list?.length != 0 &&
                this.props.activeCustomer?.card_list?.map((oldCard, index) => (
                  <FormControlLabel
                    control={
                      <Radio checked={this.state.selectedPayment == index} />
                    }
                    label={
                      <Grid container>
                        <Grid item xs={2}>
                          {oldCard.card_name == "visa" && (
                            <FontAwesomeIcon icon={faCcVisa} />
                          )}
                          {oldCard.card_name == "mastercard" && (
                            <FontAwesomeIcon icon={faCcMastercard} />
                          )}
                          {oldCard.card_name == "american-express" && (
                            <FontAwesomeIcon icon={faCcAmex} />
                          )}
                          {oldCard.card_name !== "visa" &&
                            oldCard.card_name !== "mastercard" &&
                            oldCard.card_name !== "american-express" && (
                              <FontAwesomeIcon icon={faCreditCard} />
                            )}
                        </Grid>
                        <Grid item xs={10}>
                          {oldCard.ssl_card_number}
                        </Grid>
                      </Grid>
                    }
                    onClick={() =>
                      this.setState({
                        selectedPayment: index,
                        oldCardUsed: true,
                      })
                    }
                  />
                ))}
              {this.isPayAtStoreEnabled() && (
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedPayment == "Pay at Store"}
                    />
                  }
                  label={"Pay at Store"}
                  onClick={() =>
                    this.setState({
                      selectedPayment: "Pay at Store",
                      oldCardUsed: false,
                    })
                  }
                />
              )}
              {this.isPayOnDeliveryCardEnabled() && (
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        this.state.selectedPayment == "Pay on Delivery - Cash"
                      }
                    />
                  }
                  label={"Pay on Delivery - Cash"}
                  onClick={() =>
                    this.setState({
                      selectedPayment: "Pay on Delivery - Cash",
                      oldCardUsed: false,
                    })
                  }
                />
              )}
              {this.isPayOnDeliveryCardEnabled() && (
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        this.state.selectedPayment == "Pay on Delivery - Card"
                      }
                    />
                  }
                  label={"Pay on Delivery - Card"}
                  onClick={() =>
                    this.setState({
                      selectedPayment: "Pay on Delivery - Card",
                      oldCardUsed: false,
                    })
                  }
                />
              )}
              <FormControlLabel
                control={
                  <Radio checked={this.state.selectedPayment == "Add Card"} />
                }
                label={"Add Card"}
                onClick={() =>
                  this.setState({
                    selectedPayment: "Add Card",
                    oldCardUsed: false,
                  })
                }
              />
              {this.state.selectedPayment == "Add Card" && (
                // <CreditCardInput
                //   cardNumberInputProps={{
                //     value: this.state.cardNumber,
                //     onChange: (cardNumber) => {
                //       var cardTypeArray = creditCardType(
                //         cardNumber.target.value
                //       );
                //       var cardType = cardTypeArray[0].type;
                //       this.setState({
                //         cardNumber: cardNumber.target.value,
                //         cardType: cardType,
                //       });
                //     },
                //   }}
                //   cardExpiryInputProps={{
                //     value: this.state.expDate,
                //     onChange: (exp) =>
                //       this.setState({
                //         expDate: exp.target.value,
                //       }),
                //   }}
                //   cardCVCInputProps={{
                //     value: this.state.cardCvv,
                //     onChange: (cardCvv) =>
                //       this.setState({
                //         cardCvv: cardCvv.target.value,
                //       }),
                //   }}
                //   fieldClassName="input"
                // />

                <PaymentInputsContainer>
                  {({
                    meta,
                    getCardImageProps,
                    getCardNumberProps,
                    getExpiryDateProps,
                    getCVCProps,
                    wrapperProps,
                  }) => (
                    <div>
                      <PaymentInputsWrapper {...wrapperProps}>
                        <svg {...getCardImageProps({ images })} />
                        <input
                          {...getCardNumberProps({
                            onChange: this.handleChangeCardNumber,
                          })}
                          value={this.state.cardNumber}
                        />
                        <input
                          {...getExpiryDateProps({
                            onChange: this.handleChangeExpiryDate,
                          })}
                          value={this.state.expDate}
                        />
                        <input
                          {...getCVCProps({
                            onChange: this.handleChangeCVC,
                          })}
                          value={this.state.cardCvv}
                        />
                        <input
                          name="zip"
                          type="text"
                          placeholder="Zip"
                          onChange={this.handleZipCodeChange}
                          onBlur={this.handleZipCodeBlur}
                          value={this.state.cardZip}
                          maxLength="6"
                          ref={(input) => {
                            this.zipCodeInputRef = input;
                          }}
                          style={{ marginLeft: 5, width: "20%" }}
                        />
                      </PaymentInputsWrapper>
                      {!meta.isTouched && !meta.error && this.state.zipError && (
                        <div
                          style={{
                            color: "#c9444d",
                            fontSize: "0.75rem",
                            marginTop: "0.25rem",
                          }}
                        >
                          Enter a Valid Zipcode
                        </div>
                      )}
                      {/* {meta.isTouched && meta.error && (
                          <span>Error: {meta.error}</span>
                        )} */}
                    </div>
                  )}
                </PaymentInputsContainer>
              )}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.savePayment()}
              disabled={this.isPaymentDisabled()}
              color="secondary"
              variant="contained"
            >
              Save Payment
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Snackbar
          open={this.state.showError}
          autoHideDuration={6000}
          message={this.state.errorMessage}
        /> */}
        <Modal open={this.state.showloginModal} disableBackdropClick>
          <div className={styles.loginModalStyle}>
            <LoginSignup
              nextScreen="null"
              onClose={() => this.setState({ showloginModal: false })}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: OrderSelectors.getProducts(state),
    totalCost: OrderSelectors.getTotalCost(state),
    menuItems: MenuSelectors.getMenuItems(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
    totalTaxes: OrderSelectors.getTotalTaxes(state),
    subTotal: OrderSelectors.getSubTotal(state),
    oldSubTotal: OrderSelectors.getOldSubTotal(state),
    businessId: state.menu.business_id,
    locationId: state.menu.location_id,
    orderType: StoreLoginSelectors.getOrderTypeId(state),
    orderTypeText: StoreLoginSelectors.getOrderType(state),
    modifiers: MenuSelectors.getModifierList(state),
    userInfo: StoreLoginSelectors.getUserInfo(state),
    variationList: MenuSelectors.getVariationList(state),
    token: StoreLoginSelectors.getUserToken(state),
    selectedAddress: StoreLoginSelectors.getSelectedAddress(state),
    // isScheduled: StoreLoginSelectors.getIsScheduled(state),
    // scheduledDateTimeObject: LoginSelectors.getScheduledDateTimeObject(state),
    scheduleDateTime: StoreLoginSelectors.getScheduledDateTime(state),
    restaurantTimeZone: MenuSelectors.getTimezone(state),
    restaurantName: MenuSelectors.getRestaurantName(state),
    isUserLoggedIn: StoreLoginSelectors.isUserloggedIn(state),
    coverImage: MenuSelectors.getCoverImage(state),
    tax_list: MenuSelectors.getTaxList(state),
    couponInfo: OrderSelectors.getCouponInfo(state),
    tipValue: OrderSelectors.getTipValue(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    activeCustomer: CustomerSelectors.getActiveCustomer(state),
    getTotalCostWithOrdeType: OrderSelectors.getTotalCostWithOrdeType(
      state,
      StoreLoginSelectors.getOrderTypeId(state)
    ),
    getTotalPhoneCostWithOrdeType: OrderSelectors.getTotalPhoneCostWithOrdeType(
      state,
      StoreLoginSelectors.getOrderTypeId(state)
    ),
    getTotalTaxList: OrderSelectors.getTotalTaxList(state),
    phoneDeliveryFee: OrderSelectors.getPhoneDeliveryInfo(state),
  };
};

export default connect(mapStateToProps)(withTheme(CheckoutComponent));
