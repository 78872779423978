import React, { Component } from "react";
import * as animationData from "../../assets/loading_new.json";
import Lottie from "react-lottie";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="font16Bold" style={{ textAlign: "center", paddingBottom: 15 }}>
            {this.props.title}
          </div>
        </div>
        <Lottie options={defaultOptions} height="200px" width="200px" />
      </>
    );
  }
}

export default Loading;
