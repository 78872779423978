import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";
import AddressSelection from "./AddressSelection/AddressSelection";
import OrderScreen from "./OrderScreen/OrderScreen";
import Checkout from "./Checkout/Checkout";
import OrderHistory from "./OrderHistory/OrderHistory";
import PhoneLogin from "./PhoneLogin/PhoneLogin";

class InitialPlaceholderScreen extends Component {
  constructor(props) {
    super(props);
  }

  onThemeColorChange = (color) => {
    this.props.onThemeColorChange(color);
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={this.props.match.path}
          render={() => (
            <AddressSelection onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/order`}
          render={() => (
            <OrderScreen onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/checkout`}
          render={() => (
            <Checkout onThemeColorChange={this.onThemeColorChange} />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(InitialPlaceholderScreen);
