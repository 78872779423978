import { call, put } from "redux-saga/effects";
import MenuActions from "../Redux/MenuRedux";

export function* getMenu(api, action) {
  const { business_id, location_id, order_type, date_time, web_menu } = action;

  const response = yield call(
    api.getMenu,
    business_id,
    location_id,
    order_type,
    date_time,
    web_menu
  );

  if (response.ok) {
    const menuData = response.data;
    yield put(MenuActions.menuSuccess(menuData));
  } else yield put(MenuActions.menuError());
}

export function* menuRequestDineIn(api, action) {
  const { business_id, location_id, room_name, table_number } = action;

  const response = yield call(
    api.getMenuDineIn,
    business_id,
    location_id,
    room_name,
    table_number
  );

  if (response.ok) {
    const menuData = response.data;
    yield put(MenuActions.menuSuccess(menuData));
  } else yield put(MenuActions.menuError());
}
