import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator

const { Types, Creators } = createActions({
  menuRequest: [
    "business_id",
    "location_id",
    "order_type",
    "date_time",
    "web_menu",
  ],
  menuRequestDineIn: [
    "business_id",
    "location_id",
    "room_name",
    "table_number",
  ],
  menuSuccess: ["raw_data"],
  menuError: {},
  setStateWithMenuJson: ["menuJson", "busineess_id", "location_id"],
});

export const MenuTypes = Types;
export default Creators;

// Initial State
export const INITIAL_MENU_STATE = Immutable({
  raw_details: {},
  loading: {},
  business_id: {},
  location_id: {},
  error: {},
});

// Selectors to derive data from state
export const MenuSelectors = {
  getCategories: (state) => {
    return state.menu.raw_details?.category_list;
  },
  getMenuLoadingState: (state) => {
    return {
      loading: state.menu.loading,
      error: state.menu.error,
    };
  },
  getMenuItems: (state) => state.menu.raw_details?.menu_item_list,
  getVariationList: (state) => state.menu.raw_details?.variation_list,
  getGroupList: (state) => state.menu.raw_details?.group_list,
  getModifierList: (state) => state.menu.raw_details?.modifier_list,
  getTaxList: (state) => state.menu.raw_details?.tax_list,
  getBusinessInfo: (state) => {
    return {
      name: state.menu.raw_details?.business_name,
      address: state.menu.raw_details?.business_address,
      city: state.menu.raw_details?.business_city,
      province: state.menu.raw_details?.business_province,
      postal_code: state.menu.raw_details?.business_postal_code,
      phone: state.menu.raw_details?.business_phone,
      email: state.menu.raw_details?.business_email,
      allergy_warning: state.menu.raw_details?.allergy_warning,
      age_varification: state.menu.raw_details?.age_verification_info,
      airportShortName: state.menu.raw_details?.airport_short_name,
      promotions: state.menu.raw_details?.promotions_list,
    };
  },
  getBusinessId: (state) => state.menu.business_id,
  getLocationId: (state) => state.menu.location_id,
  getCoverImage: (state) => state.menu.raw_details?.background,
  getTimezone: (state) => state.menu.raw_details?.utc_zone,
  getRestaurantName: (state) => state.menu.raw_details?.business_name,
};

// Reducers

export const requestRawMenu = (
  state,
  { business_id, location_id, order_type, date_time, web_menu }
) => {
  return { loading: true, business_id, location_id, raw_details: {} };
};

export const menuRequestDineIn = (
  state,
  { business_id, location_id, room_name, table_number }
) => {
  return { loading: true, business_id, location_id, raw_details: {} };
};

export const saveRawMenu = (state, raw_data) => {
  return {
    loading: false,
    raw_details: raw_data.raw_data,
    error: false,
    business_id: state.business_id,
    location_id: state.location_id,
  };
};

export const errorRawMenu = (state) => {
  return {
    loading: false,
    error: true,
    raw_details: {},
    business_id: state.business_id,
    location_id: state.location_id,
  };
};

export const setStateWithMenuJson = (
  state,
  menuJson,
  business_id,
  location_id
) => {
  return {
    loading: false,
    error: false,
    raw_details: menuJson,
    business_id: business_id,
    location_id: location_id,
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_MENU_STATE, {
  [Types.MENU_REQUEST]: requestRawMenu,
  [Types.MENU_REQUEST_DINE_IN]: menuRequestDineIn,
  [Types.MENU_SUCCESS]: saveRawMenu,
  [Types.MENU_ERROR]: errorRawMenu,
  [Types.SET_STATE_WITH_MENU_JSON]: setStateWithMenuJson,
});
