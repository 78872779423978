import { call, put, delay } from "redux-saga/effects";
import LoginActions from "../Redux/LoginRedux";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createUrlToScreen from "../Services/Utils";

export function* loginRequest(api, action) {
  const { username, password, nextScreen, closeFunction, currentUrl } = action;
  const response = yield call(api.loginRequest, username, password);
  if (response.ok && response.data.status !== "Failure") {
    const userInfo = response.data;
    yield put(LoginActions.loginSuccess(userInfo));
    toast.success("Welcome " + response.data.customer_name);
    if (nextScreen !== "null" && nextScreen != null) {
      yield put(push(createUrlToScreen(nextScreen, currentUrl)));
    } else {
      closeFunction();
    }
  } else {
    yield put(LoginActions.loginError());
    toast.error("Login Failed, Please try again");
  }
}

export function* forgotPassword(api, action) {
  const { email, closeFunction } = action;
  const response = yield call(api.forgotPassword, email);
  if (response.ok && response.data.status !== "Failure") {
    const userInfo = response.data;
    toast.success("Reset email has been sent to " + email);
    closeFunction();
  } else {
    toast.error("Forgot Password failed!");
  }
}

export function* resetPassword(api, action) {
  const { approveKey, password, closeFunction } = action;
  const response = yield call(api.resetPassword, approveKey, password);
  if (response.ok && response.data.status !== "Failure") {
    const userInfo = response.data;
    yield put(LoginActions.resetPasswordSuccess());
    toast.success("Password reset");
  } else {
    toast.error("Reset password failed!");
  }
}

export function* signupRequest(api, action) {
  const {
    name,
    phone,
    email,
    password,
    nextScreen,
    closeFunction,
    currentUrl,
  } = action;
  const response = yield call(api.signupRequest, name, phone, email, password);
  if (response.ok && response.data.status !== "Failure") {
    const responseObj = response.data;
    yield put(LoginActions.signupSuccess(responseObj));
    toast.success("Signup Successful");
    yield delay(2000);
    const response1 = yield call(api.loginRequest, email, password);
    if (response1.ok && response1.data.status !== "Failure") {
      const userInfo = response1.data;
      yield put(LoginActions.loginSuccess(userInfo));
      toast.success("Welcome " + response1.data.customer_name);
      if (nextScreen !== "null" && nextScreen != null) {
        yield put(push(createUrlToScreen(nextScreen, currentUrl)));
      } else {
        closeFunction();
      }
    } else {
      yield put(LoginActions.loginError());
      toast.error("Login Failed, Please try again");
    }
  } else {
    if (response.status === 400 || response.data.status === "Failure") {
      yield put(
        LoginActions.signupError(
          "The email address already exists, Please login"
        )
      );
      toast.error("The email address already exists, Please login");
    } else {
      yield put(LoginActions.signupError("Signup Failed"));
      toast.error("Signup Failed, Please try again");
    }
  }
}

export function* storeLoginRequest(api, action) {
  const {
    username,
    password,
    businessId,
    locationId,
    nextScreen,
    currentUrl,
  } = action;
  const response = yield call(
    api.storeLoginRequest,
    username,
    password,
    businessId,
    locationId
  );
  if (response.ok && response.data.status !== "Failure") {
    const userInfo = response.data;
    yield put(LoginActions.storeLoginSuccess(userInfo));
    toast.success("Welcome " + response.data.customer_name);
    if (nextScreen !== "null" && nextScreen != null) {
      yield put(push(createUrlToScreen(nextScreen, currentUrl)));
    }
  } else {
    yield put(LoginActions.loginError());
    toast.error("Login Failed, Please try again");
  }
}
