import { takeLatest, all } from "redux-saga/effects";
import MenuAPI from "../Services/MenuApi";

/* ------------- Types ------------- */

import { MenuTypes } from "../Redux/MenuRedux";
import { LoginStateTypes } from "../Redux/LoginRedux";
import { CustomerTypes } from "../Redux/CustomerRedux";

/* ------------- Sagas ------------- */

import { getMenu, menuRequestDineIn } from "./MenuSagas";
import {
  loginRequest,
  signupRequest,
  forgotPassword,
  resetPassword,
} from "./loginSagas";
import LoginApi from "../Services/loginApi";
import { OrderTypes } from "../Redux/OrderRedux";
import { sendOrderToBackend, setDeliveryFee } from "./OrderSagas";
import CustomerApi from "../Services/CustomerApi";
import { findCustomers } from "./CustomerSagas";
import { StoreLoginTypes } from "../Redux/StoreLoginRedux";
import { storeLoginRequest } from "./StoreLoginSagas";
import StoreLoginApi from "../Services/StoreLoginApi";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const menuApi = MenuAPI.create();
const loginApi = LoginApi.create();
const customerApi = CustomerApi.create();
const storeLoginApi = StoreLoginApi.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Menu sagas
    takeLatest(MenuTypes.MENU_REQUEST, getMenu, menuApi),
    takeLatest(MenuTypes.MENU_REQUEST_DINE_IN, menuRequestDineIn, menuApi),

    // Login Sagas
    takeLatest(LoginStateTypes.LOGIN_REQUEST, loginRequest, loginApi),
    takeLatest(LoginStateTypes.SIGNUP_REQUEST, signupRequest, loginApi),
    takeLatest(LoginStateTypes.FORGOT_PASSWORD, forgotPassword, loginApi),
    takeLatest(LoginStateTypes.RESET_PASSWORD, resetPassword, loginApi),

    // Customer Sagas
    takeLatest(CustomerTypes.FIND_CUSTOMERS, findCustomers, customerApi),

    //StoreLogin Sagas

    takeLatest(
      StoreLoginTypes.STORE_LOGIN_REQUEST,
      storeLoginRequest,
      storeLoginApi
    ),

    // Order Sagas
    // takeLatest(OrderTypes.PLACE_ORDER, getPlayerId),
    takeLatest(OrderTypes.PLACE_ORDER, sendOrderToBackend, menuApi),
    takeLatest(OrderTypes.SET_PHONE_DELIVERY_FEE, setDeliveryFee, menuApi),
  ]);
}
